import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL,HEADERS } from "../../utils/config";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

export const getDataOfDoc = createAsyncThunk(
    "pdfData/getDataOfDoc",
    async ({gpId, docType, userId})=>{
      try {
          const config = {
              method: 'get',
              url: `${BASE_URL}/group/${gpId}/${docType}${userId ? `/users/${userId}`: ""}`,
              headers: HEADERS(),
          };
          const response = await axios(config);
          return response.data;
      } catch (error) {
          console.log(error);
          throw new Error(error);
      }
    }
  )

const pdfDataSlice = createSlice({
  name: "pdfData",
  initialState,
  reducers: {
    resetData : (state)=>{
      state.data = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataOfDoc.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getDataOfDoc.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(getDataOfDoc.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
  },
});

export const { resetData } = pdfDataSlice.actions

export default pdfDataSlice.reducer;
