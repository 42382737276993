import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, Button, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSearchKeyword } from "../../../slices/backOffice/cvFromSlice";

const SearchInput = () => {
  const { searchKeyword } = useSelector((state) => state.CvForm);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    dispatch(setSearchKeyword(e.target.value));
  };

  useEffect(() => {
    if (searchKeyword !== "") {
      setTimeout(() => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete("page");
        searchParams.set("keyword", searchKeyword);
        navigate(`?${searchParams.toString()}`, { replace: true });
      }, 1000);
    } else {
    }
  }, [navigate, searchKeyword]);

  const reset = () => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.delete("page");
    searchParams.delete("keyword");
    searchParams.delete("company_id");
    navigate(``);
    window.location.reload();
  };

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        size="small"
        fullWidth
        placeholder="Search"
        variant="outlined"
        value={searchKeyword}
        onChange={handleInputChange}
      />

      <Button onClick={reset} variant="outlined" sx={{ ml: 1 }}>
        <RestartAltIcon />
      </Button>
    </Box>
  );
};

export default SearchInput;
