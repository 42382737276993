import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import dayjs from "dayjs";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
  },
});

const PofAtt = ({type}) => {
  const [ preData,setPreData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const classes = useStyles();
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setPreData(data)
    }
  },[pdfData])

  return (
    <div className={classes.screen}>
      <img src={letterHead} className="letterHead"></img>
      {preData && preData.predeparture && (
        <Box
          sx={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.78), rgba(255, 255, 255, 0.78)), url(${waterMark})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: "60%",
            mx: "auto",
            px: 15,
          }}
        >
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            သို့
          </Typography>
          <Box
            sx={{
              pl: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              သင်တန်းကြီးကြပ်ရေးတာ၀န်ခံအရာရှိ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              ပြည်ပအလုပ်ကိုင်လမ်းညွှန်သင်တန်း
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              မြောက်ဒဂုံမြို့နယ် ၊ ရန်ကုန်တိုင်းဒေသကြီး
            </Typography>
          </Box>
          <Typography
            sx={{
              my: "10px",
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
            }}
          >
            ရက်စွဲ။&nbsp;&nbsp;&nbsp;။
            {convertToMyanmarNumbers(splitDate(preData.predeparture.certificateIssueDate).year)}
            ခုနှစ် &nbsp;
            {getBurmeseMonthName(
              getFullMonthName(splitDate(preData.predeparture.certificateIssueDate).month)
            )}
            လ &nbsp;
            (&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)ရက်
            {/* {convertToMyanmarNumbers(splitDate(preData.predeparture.certificateIssueDate).day)}
            ရက် */}
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;။ သင်တန်းဆင်းလက်မှတ်
            ထုတ်ယူခွင့်ပြုပါရန်တင်ပြခြင်း။
          </Typography>
          <Typography
            sx={{
              textIndent: "50px",
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
              textAlign: "justify",
            }}
          >
            အထက်အကြောင်းအရာပါ ကိစ္စနှင့်စပ်လျဉ်း၍ ကျွန်တော်/မတို့ Clover
            Mandalay Co.,Ltd ပြည်ပအလုပ်အကိုင် အကျိုးဆောင်လိုင်စင်ရအေဂျင်စီမှ
            ဂျပန်နိုင်ငံသို့စေလွှတ်မည့်{" "}
            {type === "tit" ? "အလုပ်သင်အလုပ်သမား" : "ကျွမ်းကျင်အလုပ်သမား"} (
            {convertToMyanmarNumbers(preData.totalWorkers)}) ဦးသည် (
            {`${convertToMyanmarNumbers(
              splitDate(preData.predeparture.courseStartDate).year
            )} - ${convertToMyanmarNumbers(
              splitDate(preData.predeparture.courseStartDate).month
            )} - ${convertToMyanmarNumbers(
              splitDate(preData.predeparture.courseStartDate).day
            )} `}
            ) ရက်နေ့တွင် သင်တန်းတက်‌ရောက်ခဲ့ပြီး ပြည်ပအလုပ်အကိုင်လမ်းညွှန်
            သင်တန်းဆင်းလက်မှတ်ကို အောက်ဖော်ပြပါပုဂ္ဂိုလ်အား ထုတ်ယူခွင့်ပြုပါရန်
            လေးစားစွာဖြင့် တင်ပြအပ်ပါသည်။
          </Typography>
          <Typography
            sx={{
              textDecoration: "underline",
              pt: 2,
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
            }}
          >
            သင်တန်းဆင်းလက်မှတ်ထုတ်ယူမည့်ပုဂ္ဂိုလ်
          </Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "30%",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                အမည်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                နိုင်ငံသားမှတ်ပုံတင်အမှတ်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ဆက်သွယ်ရန်ဖုန်း
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                - ဦးရဲဝင်းညွန့်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                - ၁၂/သကတ(နိုင်)၀၇၉၈၄၁
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                - 09-250185796
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              pr: 5,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  my: 15,
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                }}
              >
                လေးစားစွာဖြင့်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                ချစ်စုဝေ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                မန်နေးဂျင်းဒါရိုက်တာ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                }}
              >
                Clover Mandalay Co., Ltd
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default PofAtt;
