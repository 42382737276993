import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles';
import Coe11_11_tableRow from './Coe11_11_tableRow';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { splitDate } from '../../../utils/splitDate';


const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});
const Coe11_12 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ firstMember,setFirstMember ] = useState(null)
    const [ comInfo,setComInfo ] = useState(null)
    const [ orgInfo,setOrgInfo ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const members = printData.data.members
        const company = printData.data.company
        const organization = printData.data.organization
        const firstMem = members[0]
        setOrgInfo(organization)
        setComInfo(company)
        setFirstMember(firstMem)
      }
    },[printData])

    const renderRows = () => {
        const numRows = 2;
      
        return Array.from({ length: numRows }, (_, index) => (
          <Coe11_11_tableRow index={index+1} key={index} />
        ));
    };

  return (
    <Box className={classes.screen} sx={{ p: 10 }}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <tbody>
                {renderRows()}
                <tr>
                    <td colSpan={6} style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                        合　計　時　間
                    </td>
                    <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'end', border: '2px solid #000' }}>
                        時間
                    </td>
                </tr>
            </tbody>
        </table>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' }}>（注意）</Typography>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' }}>1&nbsp;&nbsp;&nbsp;申請者（企業単独型の場合）又は監理団体（団体監理型の場合）において講習の内容が入国後講習に相当すると認めたものについてのみ記載すること。</Typography>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' }}>2&nbsp;&nbsp;&nbsp;外国の教育機関とは、現地において正規の教育機関として認定されている学校であって義務教育修了後に入学するものをいうものであること。</Typography>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' }}>3&nbsp;&nbsp;&nbsp;外国の公私の機関とは、法第２条第２項第１号に規定する外国の公私の機関をいうものであること（企業単独型の場合）。</Typography>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'justify' }}>4&nbsp;&nbsp;&nbsp;実施機関の種別については、該当する種別に丸印を付すこと。</Typography>
        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'right' }}>
            {firstMember && `${splitDate(firstMember.coeDcDate).year} 年 `}&nbsp;&nbsp;
            {firstMember && `${splitDate(firstMember.coeDcDate).month} 月 `}&nbsp;&nbsp;
            {firstMember && `${splitDate(firstMember.coeDcDate).day} 日 `}
        </Typography>
        <table style={{ width: '100%', marginTop: "30px"}}>
            <tbody>
                <tr>
                    <td style={{ width: '50%' }}>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（企業単独型）申請者の氏名又は名称</Typography>
                    </td>
                    <td style={{ width: '50%' }}>
                        <Box sx={{
                            borderBottom: '1px solid black',
                        }}>
                            <Typography sx={{ ml: 5, fontFamily: 'Mincho', fontSize: '20px' }}>{comInfo ? comInfo.nameJp : ''}</Typography>
                        </Box>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '50%' }}>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（団体監理型）監理団体の名称</Typography>
                    </td>
                    <td style={{ width: '50%' }}>
                        <Box sx={{
                            borderBottom: '1px solid black',
                        }}>
                            <Typography sx={{ ml: 5, fontFamily: 'Mincho', fontSize: '20px' }}>{orgInfo ? orgInfo.nameJp : ''}</Typography>
                        </Box>
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            pt: '10px'
                         }}>
                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>作成責任者　役職・氏名</Typography>
                            <Box sx={{
                                borderBottom: '1px solid black',
                                width: '200px',
                                pl: '10px'
                            }}>
                                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{orgInfo ? orgInfo.chairmanJp : ''}</Typography>
                            </Box>
                         </Box>
                    </td>
                </tr>
            </tbody>
        </table>
    </Box>
  )
}

export default Coe11_12