import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_4 = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px' ,fontWeight: 'bold' }}>အလုပ်သင်သင်တန်းသား၏တာဝန်နှင့်ဝတ္တရား</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' ,fontWeight: 'bold', mb: 3 }}>（技能実習生の役割と義務）</Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၁။ သင်သည် ဂျပန်နိုင်ငံ၏ကြီးကြပ်မည့်အဖွဲ့အစည်း၊ လက်ခံမည့်လုပ်ငန်း၏ သင်တန်းအကြောင်းအရာ၊ လုပ်ငန်းအကြောင်းအရာ၊ အလုပ်ခန့်အပ်ခြင်းဆိုင်ရာသဘောတူစာချုပ်ပါအကြောင်းအရာ၊ လုပ်ခလစာနှင့်လိုအပ်သော ကျွမ်းကျင်မှုစသည်တို့ကို  သေသေချာချာ ဖတ်ရှုစစ်ဆေး၍ အမှန်တကယ် လုပ်ကိုင်နိုင်မှသာ ဝင်ရောက်ဖြေဆိုရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                1.　あなたは日本の受け入れ監理団体、受け入れ機関の技能実習事業の内容、従事する仕事の内容、雇用契約書の内容、賃金と満たすべき技術スキルなどをよく理解して実際に勤める自信があれば受験すること。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၂။ လုပ်ငန်းအင်တာဗျူးအောင်မြင်လျှင် ဤသဘောတူစာချုပ်ကိုချုပ်ဆိုပြီး အေဂျင်စီမှသတ်မှတ်ထားသည့်အတိုင်း ဝန်ဆောင်ခပေးချေကာ၊ လိုအပ်သောကျန်းမာရေးဆေးစစ်မှုကို ပြုလုပ်ရမည်ဖြစ်ပြီး၊ ပြည်ပသို့မထွက်ခွာမီ လိုအပ်ပါက ထပ်မံ၍ ဆေးစစ်မှုပြုလုပ်ရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                2.　面接で合格したとき雇用契約書を締結して当エージェンシーが定めた手数料を納付して定期的な健康診断を受ける。また、出国前に必要に応じて再検査を受ける場合もある。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၃။ သင်သည် သင်၏ ကိုယ်ရေးရာဇဝင်နှင့် လိုအပ်သောစာရွက်စာတမ်းအချက်အလက်များ၊ အိမ်ထောင်စုစာရင်း၊ မှတ်ပုံတင်၊ နိုင်ငံကူးလက်မှတ်၊ ဆေးစစ်ချက်များကို ပြည့်စုံမှန်ကန်စွာပေးရမည်။ ပြည့်စုံမှန်ကုန်မှုမရှိဘဲ ဂျပန်နိုင်ငံ၏ တာဝန်ရှိအဖွဲ့အစည်းများမှ စစ်ဆေး၍ လိမ်လည်စာရင်းသွင်းခြင်းစသည့် မှားယွင်းမှုတစ်စုံတစ်ရာကြောင့် လျှောက်ထား ခြင်း အသိအမှတ်ပြုမခံရလျှင် သင်မှ တာဝန်ယူရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                3.　あなたは自己の履歴書、必要な資料とデータ、世帯員一覧表、国民登録証書、パスポート、健康診断書などを揃えて提出する。日本の該当機関より検査された結果不十分であったり、誤ったことで申請が不交付ななった場合あなたの責任になります。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၄။ လက်ခံမည့်လုပ်ငန်းနှင့်စာချုပ်ချုပ်ဆိုပြီးနောက် လိုအပ်သောဂျပန်ဘာသာစကားသင်ကြားခြင်းနှင့် ဂျပန်နိုင်ငံ၏ ဥပဒေ၊ စည်းကမ်း၊ နေထိုင်မှုပုံစံ၊ ယဉ်ကျေးမှု စသည်တို့ကို နားလည်သဘောပေါက်စေရန် အနည်းဆုံး ၁လ နှင့် အထက် စာတွေ့လက်တွေ့သင်ကြားကို တက်ရောက်၍ ကြိုးစားသင်ယူရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                4.　受け入れ機関と雇用契約書を締結した後必要な日本語を学んで、日本の法律、規則、生活、文化などを理解するまで最低１ヶ月以上　理論的および実践的に一生懸命勉強しなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၅။ ဂျပန်နိုင်ငံသို့ရောက်ရှိပြီးနောက် ဂျပန်နိုင်ငံ၏ကြီးကြပ်မည့်အဖွဲ့အစည်း၊ လက်ခံမည့်လုပ်ငန်း၏ သင်တန်း အကြောင်းအရာ၊ လုပ်ငန်းအကြောင်းအရာ၊ အလုပ်ခန့်အပ်ခြင်းဆိုင်ရာသဘောတူစာချုပ်ပါအကြောင်းအရာနှင့် လုပ်ငန်း ခွင်တွင် ချမှတ်ထားသောစည်းကမ်းများကို လေးစားလိုက်နာရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                5.　日本に入国後日本の監理機関、受け入れ機関の講座、仕事の規則、雇用契約書の条件、就職規則などを遵守しなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၆။ သင်သည် လုပ်သက် ၁နှစ်ပြည့်ပြီးနောက် လုပ်ငန်းခွင်ကျွမ်းကျင်မှုသင်တန်းကို ဖြေဆိုရမည်ဖြစ်ပြီး အောင်မြင်ပါက ဗီဇာသက်တမ်းတိုးပေးမည်ဖြစ်ကြောင်း သိရှိနားလည်ရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                6.　あなたは入国して一年に達したとき（「技能実習１号」から「技能実習２号」へ移行するためには）技能評価試験を受検して合格した場合滞在期間は更新される。
            </Typography>
        </Box>
    )
}

export default Coe9_4