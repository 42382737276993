import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const Coe7_1_2 = ({index}) => {
  const tableCellStyle = {
    textAlign: "center",
    padding: "5px",
    border: '2px solid #000',
  };

  const { data: printData } = useSelector(state=> state.PrintData)
  const [ memberData,setMemberData ] = useState([])
  const [ yenPerDollar,setYenPerDollar ] = useState(0)
  
  useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[index]
        const yen = data.currencyExchange ? data.currencyExchange.find(rate=> rate.key === 'yen_per_dollar')?.value : 0
        setYenPerDollar(Number(yen));
        setMemberData(data)
      }
  },[printData, index])

  const renderTableRow = (number, title, date, amount) => (
    <tr key={number}>
      <td style={tableCellStyle}>
        <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>{number}</Typography>
      </td>
      <td style={tableCellStyle}>
        <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>{title}</Typography>
      </td>
      <td style={{ ...tableCellStyle, width: '220px' }}>
        <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px', textAlign: 'end' }}>{date}</Typography>
      </td>
      <td style={tableCellStyle}>
        <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>{amount}</Typography>
      </td>
    </tr>
  );

  return (
    <>
      <Typography sx={{ fontSize: '22px', fontFamily: 'Mincho', mt: 5, mb: 2 }}>
        ２　取次送出機関が徴収した費用の名目及び額
      </Typography>

      <table border={1} style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <td style={{ width: '35px', border: '2px solid #000' }}></td>
            <td style={tableCellStyle}>名目</td>
            <td style={tableCellStyle}>徴収年月日</td>
            <td style={tableCellStyle}>額</td>
          </tr>
        </thead>
        <tbody>
          {renderTableRow(1, '選考関連費用', `${splitDate(memberData.coeDcDate).year}年 ${splitDate(memberData.coeDcDate).month}月 ${splitDate(memberData.coeDcDate).day}日`, `US$600 (${yenPerDollar * 600} 円)`)}
          {renderTableRow(2, '各種申請手続費用', `${splitDate(memberData.coeDcDate).year}年 ${splitDate(memberData.coeDcDate).month}月 ${splitDate(memberData.coeDcDate).day}日`, `US$1000 (${yenPerDollar * 1000} 円)`)}
          {renderTableRow(3, '送出機関手数料', '技能実習生出国決定後', `US$1000 (${yenPerDollar * 1000}円)`)}
          {renderTableRow(4, '健康診断料', '日本に出国まで', `US$100 (${yenPerDollar * 100} 円)`)}
          {renderTableRow(5, '講習費用', '日本に出国まで', `US$100 (${yenPerDollar * 100} 円)`)}
          <tr>
            <td colSpan={3} style={{ borderLeft: "1px dotted white", borderBottom: "1px dotted white" }}></td>
            <td align="right" style={tableCellStyle}>
              <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>
                計 US$2800 (${yenPerDollar * 2800} 円)
              </Typography>
            </td>
          </tr>
        </tbody>
      </table>

      <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>（注意）</Typography>
      <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>
        １　「その他」の徴収費用については、括弧書きで名目を記載すること。
      </Typography>
      <Typography sx={{ fontSize: "20px", fontFamily: 'Mincho', lineHeight: '30px' }}>
        ２　額については、現地通貨又は米ドルで記載し、括弧書きで日本円に換算した金額を記載すること。
      </Typography>
    </>
  );
};

export default Coe7_1_2;
