import React, { Fragment, useEffect, useState } from 'react'
import PredepCvForm from '../common/PredepCvForm'
import MoeafCoverLetter from './MoeafCoverLetter'
import NDCoverLetter from './NDCoverLetter'
import PofAtt from './PofAtt'
import ListOfWorker from './ListOfWorker'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDataOfDoc } from '../../../../slices/backOffice/pdfDataSlice'
import Loading from '../../../utils/Loading'
import ErrorDialog from '../../../utils/ErrorDialog'

const PredepTitssw = ({gpOrMem,id,toggleDialog}) => {
  const dispatch = useDispatch()
  const gpId = useParams().id
  const { data: singleGroup } = useSelector(state=> state.IntGroup.group)
  const { data: pdfData, loading } = useSelector(state=> state.PrintData)
  const [ alertToggle, setAlertToggle ] = useState(false);

  const getData = async()=>{
    const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'predeparture', userId: gpOrMem === 'member' ? id : null}))
    if(dispatchData.error){
      setAlertToggle(true)
    }
  } 

  const handleConfirmAlert = async () => {
    setAlertToggle(false);
    toggleDialog(false)
  };

  useEffect(()=>{
    getData()
  },[])

  return (
    <div>
      {
        loading ?
        <Loading></Loading>
        :
        singleGroup && pdfData ?
        <>
          <MoeafCoverLetter type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></MoeafCoverLetter>
          <NDCoverLetter type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></NDCoverLetter>
          <ListOfWorker type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></ListOfWorker>
          <PofAtt type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></PofAtt>
          {
            ( pdfData && pdfData.data && pdfData.data.cvForms && pdfData.data.cvForms.length > 0) &&
              pdfData.data.cvForms.map((formData,index)=>{
              return  <Fragment key={index}>
                        <PredepCvForm courseDate={pdfData.data?.predeparture?.courseStartDate || ""} data={formData} type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></PredepCvForm>
                      </Fragment>
            })
          }
        </>:
        <ErrorDialog
          type={'danger'}
          confrim={handleConfirmAlert}
          toggle={alertToggle}
          setToggle={setAlertToggle}
          title={"Oops - something went wrong!"}
          content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
      }
    </div>
  )
}

export default PredepTitssw
