import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe10 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[0]
        setMemberData(data)
      }
    },[printData])

    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            {
                memberData &&
                <>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}>
                        <Box>
                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '16px' }}>参考様式第１-13号（規則第８条第12号関係）</Typography>
                            <Typography sx={{ fontFamily: 'Mincho', fontSize: '16px' }}>Ａ・Ｂ・Ｃ・Ｄ・Ｅ・Ｆ</Typography>
                        </Box>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '16px' }}>（日本産業規格Ａ列４）</Typography>
                    </Box>
                    <Typography sx={{
                        py: 5,
                        letterSpacing: '10px',
                        textAlign: 'center',
                        fontFamily: 'Mincho',
                        fontSize: '22px'
                    }}>外国の準備機関の概要書及び誓約書</Typography>
                    <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px', width: '90px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>① 機関名</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '15px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>KAKEHASHI Japanese Language Centre</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>② 代表者の氏名</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '15px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>MS. CHIT SU WAI</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>③ 所在地</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '10px', paddingLeft: '15px' }}>
                                    <Typography sx={{
                                        fontFamily: 'Mincho', fontSize: '20px', letterSpacing: '0px'
                                    }}>
                                    NO.(3), 62A Street, Between 30th x 31st Streets, 62nd x 63rd Streets, <br/>Block no.(816), Pyi Gyi Myat Shin,
                                    Chan Aye Thar San Township,Mandalay, Myanmar.
                                    </Typography>
                                    <Typography sx={{ textAlign: 'end', fontFamily: 'Mincho', fontSize: '20px' }}>（電話　<span style={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>95-9-777510878</span>）</Typography>
                                    <Typography sx={{ textAlign: 'end' }}>（<span style={{ letterSpacing: '10px', fontFamily: 'Mincho', fontSize: '20px' }}>E-mail:info@kjla.info</span>）</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>④ 設立年月日</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '15px' }}>
                                    <Typography sx={{ letterSpacing: '5px', fontFamily: 'Mincho', fontSize: '20px' }}>2017 年 &nbsp;&nbsp;07 月&nbsp;&nbsp; 14 日</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑤技能実習生との関係</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '15px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>■　入国前講習の実施に関与する者</Typography>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>☐　その他（準備内容：<span style={{ marginLeft: '350px' }}></span>）</Typography>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ border: '2px solid #000', padding: '5px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>⑥業種、主要製品及び主要業務</Typography>
                                </td>
                                <td style={{ border: '2px solid #000', padding: '15px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>教育サービス業、日本語教育。</Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Typography sx={{
                        py: 3,
                        textIndent: '20px',
                        fontFamily: 'Mincho',
                        fontSize: '20px'
                    }}>
                        上記の記載内容は、事実と相違ありません。また、技能実習の準備に関し、技能実習に関する法令に違反することは、決していたしません。
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                        <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(memberData.coeDcDate).year} 年</Typography>
                        <Typography sx={{ ml: 10, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(memberData.coeDcDate).month} 月</Typography>
                        <Typography sx={{ ml: 10, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(memberData.coeDcDate).day} 日</Typography>
                        <Typography sx={{ ml: 10, fontFamily: 'Mincho', fontSize: '20px' }}>作成</Typography>
                    </Box>
                    <Typography sx={{
                        textAlign: 'end',
                        mr: 3,
                        py: 5,
                        fontFamily: 'Mincho',
                        fontSize: '20px'
                    }}>外国の準備機関の名称　:KAKEHASHI Japanese Language Centre</Typography>
                    <Typography sx={{
                        textAlign: 'end',
                        mr: 3,
                        fontFamily: 'Mincho',
                        fontSize: '20px'
                    }}>作成責任者　役職・氏名　校長・MS.CHIT SU WAI 
                        <span className="roundedBorder" style={{ fontFamily: 'Mincho',
                            fontSize: '22px',
                            marginLeft: '50px'
                        }}>印</span>
                    </Typography>
                </>
                }
            </Box>
    )
}

export default Coe10