import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe11_8 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ trainingDates,setTrainingDates ] = useState([])
    const [ firstPartDays,setFirstPartDays ] = useState([])
    const [ secondPartDays,setSecondPartDays ] = useState([])
    const [ offDays,setOffDays ] = useState([])
    const [ onDays,setOnDays ] = useState([])
    const days = ["日","月","火","水","木","金","土"]

    const renderCourse = (dateData) => {
        const isFirst = firstPartDays.find(eachDay => eachDay.date === dateData.date);
        const isOff = offDays.find(eachDay => eachDay.date === dateData.date)
        const isSecond = secondPartDays.find(eachDay => eachDay.date === dateData.date);
        // return isFirst ? "日本語" : "休日";
        if(isFirst){
            return "日本語"
        }else if(isOff){
            return "休日"
        }else if(isSecond){
            let label1 = "";
            let label2 = "";
            if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 0){
                label1 = "日本の歴史・文化"
                label2 = "日本の歴史・文化"
            }else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 1){
                label1 = "日本の歴史・文化"
                label2 = "生活様式"
            }else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 2){
                label1 = "生活様式"
                label2 = "生活様式"
            }
            else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 3 || secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 4){
                label1 = "職場のルール"
                label2 = "職場のルール"
            }else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 5 || secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 6){
                label1 = "修得技能の目標・内容"
                label2 = "修得技能の目標・内容"
            }else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 7 ){
                label1 = "修得技能の目標・内容"
                label2 = "職場規律･心構え"
            }else if(secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 8 || secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === dateData.date)) === 9){
                label1 = "職場規律･心構え"
                label2 = "職場規律･心構え"
            }
            return(
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ borderRight: '0.5px solid #000', width: '50%', height: '37px' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho'}}>{label1}</Typography>
                    </Box>
                    <Box sx={{ borderLeft: '0.5px solid #000', width: '50%', height: '37px' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho'}}>{label2}</Typography>
                    </Box>
                </Box>
            )
        }
    }

    const renderTime = (dateData) => {
        const isFirst = firstPartDays.find(eachDay => eachDay.date === dateData.date);
        const isSecond = secondPartDays.find(eachDay => eachDay.date === dateData.date);
        if(isFirst){
            return "4"
        }else if(isSecond){
            return "5"
        }
    }
    
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const allDays = printData.data.trainingDates
        const offs = allDays.filter(el=> el.classStatus === false)
        const ons = allDays.filter(el=> el.classStatus === true)
        const data = printData.data.trainingDates.slice(29,62)
        setOffDays(offs)
        setOnDays(ons)
        setFirstPartDays(ons.slice(0, 40))
        setSecondPartDays(ons.slice(40))
        setTrainingDates(data)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}></td>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}></td>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}></td>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none'}}></td>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none', width: '60px'}}></td>
                    </tr>
                    <tr>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none' }}></td>
                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: 'none' }}></td>
                    </tr>
                    {
                        trainingDates.map((each, index) => {
                            const currentMonth = splitDate(each.date).month;
                            const previousMonth = index > 0 ? splitDate(trainingDates[index - 1].date).month : null;
                            return (
                                <Fragment key={index}>
                                    {
                                        (secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === each.date)) === 0 ||
                                        secondPartDays.indexOf(secondPartDays.find(eachDay => eachDay.date === each.date)) === 5)
                                        &&
                                        <>
                                            <tr>
                                                <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}>月</td>
                                                <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}>日</td>
                                                <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', width: '40px', verticalAlign: 'bottom'}} rowSpan={2}>曜 <br/> 日</td>
                                                <td colSpan={2} style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px'}}>講習内容</td>
                                                <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', width: '60px'}}>時間数</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Box sx={{ borderRight: '0.5px solid #000', width: '50%', height: '37px' }}>
                                                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', textAlign: 'center'}}>午前（9:00～11:30）</Typography>
                                                        </Box>
                                                        <Box sx={{ borderLeft: '0.5px solid #000', width: '50%', height: '37px' }}>
                                                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', textAlign: 'center'}}>午後（1:00～3:30）</Typography>
                                                        </Box>
                                                    </Box>
                                                </td>
                                                <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px'}}></td>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px', width: '40px' }}>
                                            {previousMonth !== currentMonth && currentMonth}
                                        </td>
                                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px', width: '40px' }}>
                                            {splitDate(each.date).day}
                                        </td>
                                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px', width: '40px' }}>
                                            {days[each.day]}
                                        </td>
                                        <td colSpan={2} style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000' }}>
                                            { renderCourse(each) }
                                        </td>
                                        <td style={{ fontFamily: 'Mincho', fontSize: '18px', textAlign: 'center', border: '2px solid #000', padding: '5px', width: '60px' }}>
                                            { renderTime(each) }
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })
                    }
                </tbody>
            </table>
        </Box>
    )
}

export default Coe11_8