export const getBurmeseMonthName = (englishMonth) => {
    const monthMapping = {
      'January': 'ဇန်နဝါရီ',
      'February': 'ဖေဖော်ဝါရီ',
      'March': 'မတ်',
      'April': 'ဧပြီ',
      'May': 'မေ',
      'June': 'ဇွန်',
      'July': 'ဇူလိုင်',
      'August': 'ဩဂုတ်',
      'September': 'စက်တင်ဘာ',
      'October': 'အောက်တိုဘာ',
      'November': 'နိုဝင်ဘာ',
      'December': 'ဒီဇင်ဘာ'
    };
  
    return monthMapping[englishMonth] || null;
}; 
  