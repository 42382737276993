import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { getAge } from "../../../../utils/getAge";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import dayjs from "dayjs";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const PredepCvForm = ({ data, type, courseDate }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (data) setFormData(data);
  }, [data]);

  return (
    <div className={classes.screen}>
      {
        formData && (
        <Box sx={{ pt: 5, px: 15 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "19px",
                paddingTop: "15px",
                lineHeight: "30px",
              }}
            >
              ပြည်ပသို့ သွားရောက်လုပ်ကိုင်မည့်သူများ သင်တန်းတက်ရောက်ရန်
              လျှောက်လွှာ
            </Typography>
            <Box
              sx={{
                height: "120px",
                width: "120px",
                border: "1px solid black",
                position: "absolute",
                top: 0,
                right: 0,
              }}
            />
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "19px",
              paddingTop: "15px",
              lineHeight: "30px",
            }}
          >
            သို့
          </Typography>
          <Box
            sx={{
              textIndent: "50px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "19px",
                paddingTop: "15px",
                lineHeight: "30px",
              }}
            >
              ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်း
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "19px",
                paddingTop: "15px",
                lineHeight: "30px",
              }}
            >
              ရန်ကုန်မြို့
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "19px",
              paddingTop: "15px",
              lineHeight: "30px",
            }}
          >
            အကြောင်းအရာ ။ &nbsp; &nbsp;
            &nbsp;ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းသားအဖြစ် ဝင်ခွင့်ပြုရန်
            လျှောက်ထားခြင်း
          </Typography>
          <Typography
            sx={{
              textIndent: "50px",
              fontFamily: "PdsFont",
              fontSize: "19px",
              pb: 2,
              textAlign: "justify",
            }}
          >
            ကျွန်တော်/ကျွန်မ{" "}
            <span
              style={{
                borderBottom: "1px dotted black",
                padding: "0px 20px",
                fontFamily: "PdsFont",
                fontSize: "19px",
                fontWeight: "bold",
                lineHeight: "40px",
              }}
            >
              {formData.nameMm}
            </span>{" "}
            အား ပြည်ပသို့ သွားရောက်လုပ်ကိုင်နိုင်ရန် ဌာနမှဖွင့်လှစ်သော
            ပြည်ပအလုပ်အကိုင်လမ်းညွှန်သင်တန်းသို့ တက်ရောက်ခွင့်ပြုပါရန် အောက်ပါ
            အချက်များဖြင့်ဖော်ပြ၍ လျှောက်ထားအပ်ပါသည်-
          </Typography>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  ၁။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  (က)
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  အမည်( မြန်မာဘာသာဖြင့် )
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.nameMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    paddingLeft: "50px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ( အင်္ဂလိပ်ဘာသာဖြင့် )
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.nameEng}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  (ခ)
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  အသက်(မွေးသက္ကရာဇ်)
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {getAge(formData.dateOfBirth)}နှစ်( {dayjs(formData.dateOfBirth).format('DD-MM-YYYY')} )
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  (ဂ)
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  နိုင်ငံသားစိစစ်ရေးကဒ်ပြားအမှတ်
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.nrcMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  (ဃ)
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  နိုင်ငံကူးလက်မှတ်အမှတ်နှင့်
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.passport}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ထုတ်ပေးသည့်ရက်စွဲရှိလျှင်
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.passportDate ? dayjs(formData.passportDate).format('DD-MM-YYYY') : "-"}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                >
                  (င)
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  လူမျိုးနှင့်ဘာသာ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.enthnicityAndReligionMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၂။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  မိဘအမည် (မြန်မာ/အင်္ဂလိပ်)
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.fatherNameMm}({formData.fatherNameEng})
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                ></td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.motherNameMm}({formData.motherNameEng})
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၃။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  မိဘနေရပ်လိပ်စာ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      whiteSpace: "nowrap",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.addressNoMm ? `${formData.addressNoMm} ၊ `: ""}
                    {formData.roadMm ? `${formData.roadMm} ၊ `: ""}
                    {formData.wardOrVillageMm ? `${formData.wardOrVillageMm} ၊ `: ""}
                    {formData.townshipMm ? `${formData.townshipMm} ၊ `: ""}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.wardOrVillageMm ? `${formData.wardOrVillageMm} ၊ `: ""}
                    {formData.townshipMm ? `${formData.townshipMm} ၊ `: ""}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                ></td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.stateOrDivisionMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၄။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  အိမ်ထောင်ရှိ/မရှိ၊ သား/သမီးရှိ/မရှိ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: 'center'
                    }}
                  >
                    အိမ်ထောင်- {Number(formData.marriageStatus) === 1 ? "ရှိ" : "မရှိ"}
                    {
                      formData.spouseName ?
                      `၊ ${formData.spouseName} (${formData.spouseAge}) နှစ်၊ (${formData.spouseAddress})`
                      : ""
                    }
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >ရှိလျှင် အမည်၊ အသက်နှင့် လိပ်စာ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: 'center'
                    }}
                  >
                    သား၊သမီး- {formData.children ? "ရှိ" : "မရှိ"}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                    {
                      formData.children &&
                      formData.children.map((child,index)=>{
                        return  <Fragment key={index}>
                                  <p
                                    style={{
                                      fontFamily: "PdsFont",
                                      fontSize: "19px",
                                      paddingTop: "15px",
                                    }}
                                  >
                                    ({convertToMyanmarNumbers(index+1)})&nbsp;
                                    {child.nameMm} ({child.age})နှစ်၊ {child.address}။
                                  </p>
                                  <hr style={{ border: '1px solid #000' }}></hr>
                                </Fragment>
                      })
                    }
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၅။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ပညာအရည်အချင်း
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.educationStatus}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၆။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  အမြဲတမ်းနေရပ်လိပ်စာ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {formData.addressNoMm ? `${formData.addressNoMm}၊ `: ""}
                    {formData.roadMm ? `${formData.roadMm}၊`: ""}
                    {formData.wardOrVillageMm ? `${formData.wardOrVillageMm}၊ `: ""}
                    {formData.townshipMm ? `${formData.townshipMm}`: ""}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              {/* <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.wardOrVillageMm ? `${formData.wardOrVillageMm}၊ `: ""}
                    {formData.townshipMm ? `${formData.townshipMm}`: ""}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr> */}
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                ></td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.stateOrDivisionMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၇။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  ဖုန်းနံပါတ်/Viberနံပါတ်၊ E-mail
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    ဖုန်း- {formData.phone}၊ Viber- {formData.phone}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                ></td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                ></td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    Email- {formData.email}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၈။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  သွားရောက်လိုသည့်နိုင်ငံ
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    ဂျပန်
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                  }}
                  colSpan={2}
                >
                  ၉။
                </td>
                <td
                  style={{
                    fontFamily: "PdsFont",
                    fontSize: "19px",
                    paddingTop: "15px",
                    whiteSpace: "nowrap",
                  }}
                >
                  လုပ်ကိုင်ရန်ရည်ရွယ်သည့်အလုပ်အကိုင် အမျိုးအစား
                </td>
                <td style={{ width: "500px", paddingLeft: "50px" }}>
                  <p
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                      textAlign: "center",
                    }}
                  >
                    {formData.jobCategoryMm}
                  </p>
                  <hr style={{ border: '1px solid #000' }}></hr>
                </td>
              </tr>
            </tbody>
          </table>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              mt: 10,
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                    }}
                  >
                    လက်မှတ်
                  </td>
                  <td
                    style={{
                      width: "300px",
                      paddingLeft: "50px",
                      minHeight: "50px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "PdsFont",
                        fontSize: "19px",
                        paddingTop: "15px",
                        textAlign: "center",
                      }}
                    ></p>
                    <div
                      style={{
                        paddingLeft: "50px",
                        height: "1px",
                        backgroundColor: "#000",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                    }}
                  >
                    အမည်
                  </td>
                  <td
                    style={{
                      width: "300px",
                      paddingLeft: "50px",
                      minHeight: "50px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "PdsFont",
                        fontSize: "19px",
                        paddingTop: "15px",
                        textAlign: "center",
                      }}
                    >
                      {formData.nameMm}
                    </p>
                    <div
                      style={{
                        paddingLeft: "50px",
                        height: "1px",
                        backgroundColor: "#000",
                      }}
                    ></div>
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      fontFamily: "PdsFont",
                      fontSize: "19px",
                      paddingTop: "15px",
                    }}
                  >
                    ရက်စွဲ
                  </td>
                  <td
                    style={{
                      width: "300px",
                      paddingLeft: "50px",
                      minHeight: "50px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "PdsFont",
                        fontSize: "19px",
                        paddingTop: "15px",
                        textAlign: "center",
                      }}
                    >
                      &nbsp;&nbsp;&nbsp;
                      {/* {convertToMyanmarNumbers(splitDate(courseDate).day)}.
                      {convertToMyanmarNumbers(splitDate(courseDate).month)}.
                      {convertToMyanmarNumbers(splitDate(courseDate).year)} */}
                    </p>
                    <div
                      style={{
                        paddingLeft: "50px",
                        height: "1px",
                        backgroundColor: "#000",
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <div style={{ height: "50px", width: "100%" }}></div>
        </Box>
      )}
    </div>
  );
};

export default PredepCvForm;
