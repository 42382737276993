import Pagination from "@mui/material/Pagination";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPage } from "../../slices/backOffice/cvFromSlice";

const AppPagination = ({ pageCount, currentPage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event, value) => {
    dispatch(setCurrentPage(value));

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("page", value);

    searchParams.delete("keyword");
    searchParams.delete("company_id");
    navigate(`?${searchParams.toString()}`);
  };

  return (
    <Pagination
      onChange={handleChange}
      sx={{ py: 3, display: "flex", justifyContent: "center" }}
      page={currentPage}
      count={pageCount}
      color="primary"
    />
  );
};

export default AppPagination;
