import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { convertYenToMmk } from "../../../../utils/convertYenToMmk";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  dottedUnderline: {
    borderBottom: "1px dotted #000",
    display: "inline",
    fontFamily: "PdsFont",
    fontSize: '20px'
  },
});

const NDCoverLetter = ({ndData}) => {
  const [ data,setData ] = useState(null)
  const classes = useStyles();
  
  useEffect(()=>{
    if(ndData){
      setData(ndData)
    }
  },[ndData])
  
  return (
    <div className={classes.screen}>
      
      {
        data && (
        <Box
          sx={{
            mx: "auto",
            p: 15,
          }}
        >
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            သို့
          </Typography>
          <Box
            sx={{
              pl: 3,
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              ညွှန်ကြားရေးမှူးချုပ်{" "}
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              အလုပ်သမားဝန်ကြီးဌာန
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              ရုံးအမှတ် ၅၁၊ အလုပ်သမားဝန်ကြီးဌာန၊ နေပြည်တော်
            </Typography>
          </Box>
          <Typography
            sx={{
              my: "10px",
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
            }}
          >
            ရက်စွဲ။&nbsp;&nbsp;&nbsp;။
            {convertToMyanmarNumbers(splitDate(data.smartCardDate).year)}
            ခုနှစ် &nbsp;၊
            {getBurmeseMonthName(
              getFullMonthName(splitDate(data.smartCardDate).month)
            )}
            လ ၊&nbsp;({convertToMyanmarNumbers(splitDate(data.smartCardDate).day)}) ရက်
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
          >
            အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OWIC
            ပြုလုပ်ခွင့်ပြုရန် တင်ပြခြင်း။
          </Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
              textAlign: "justify",
            }}
          >
            ၁။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;အထက်အကြောင်းအရာပါကိစ္စနှင့်ပတ်သက်၍&nbsp;
            <span className={classes.dottedUnderline}>{data.stateOrDivisionMm}&nbsp;</span>
            တိုင်းဒေသကြီး ၊
            <span className={classes.dottedUnderline}>{data.townshipMm}&nbsp;</span>
            မြို့နယ် ၊<span className={classes.dottedUnderline}>{data.wardOrVillageMm}</span>{" "}
            ရပ်ကွက်၊&nbsp;
            <span className={classes.dottedUnderline}>{data.roadMm}</span>&nbsp;လမ်း၊ အမှတ်
            ( <span className={classes.dottedUnderline}>{data.addressNoMm}</span> ) နေ
            {Number(data.gender) === 1 ? "ကျွန်တော် " : "ကျွန်မ "}&nbsp;
            <span className={classes.dottedUnderline}>{data.nameMm}</span>&nbsp;
            နိုင်ငံသားစိစစ်ရေးကတ်အမှတ်<br></br>
            <span className={classes.dottedUnderline}>{data.nrcMm}</span> (
            သာမန် မြန်မာနိုင်ငံကူးလက်မှတ်အမှတ် &#123; Passport No &#125; &nbsp;
            <span className={classes.dottedUnderline}>{data.passport}</span> ) သည်
            <span className={classes.dottedUnderline}> ဂျပန်</span> နိုင်ငံ၊
            <span className={classes.dottedUnderline}> {data.workplace}</span>&nbsp;ဒေသရှိ&nbsp;
            {/* <span className={classes.dottedUnderline}>{data.company.nameEng}</span> */}
            &nbsp;ကုမ္ပဏီတွင်&nbsp;
            <span className={classes.dottedUnderline}>{data.jobCategoryMm}</span>&nbsp;
            အလုပ်ဖြင့် ယန်း &nbsp;
            <span className={classes.dottedUnderline}>{convertToMyanmarNumbers(data.basicSalary)}</span> လစာ ( မြန်မာငွေ{" "}
            <span className={classes.dottedUnderline}>
              {data.basicSalary && convertToMyanmarNumbers(convertYenToMmk(Number(data.basicSalary), data.currencyExchangeRate.length > 0 ? Number(data.currencyExchangeRate.find(rate=> rate.key === 'mmk_per_yen').value) : 0))}
            </span> သိန်းခန့်)
            ရရှိမည်ဖြစ်သော အလုပ်အကိုင်အား အလုပ်ရှင်မှ တိုက်ရိုက်ခေါ်ဆိုသော
            အလုပ်လျှောက်လွှာကို မိမိ၏အစီအစဉ်ဖြင့် လျှောက်ထားခြင်း၊
            အင်တာဗျူးအောင်မြင်ခြင်းများကြောင့် အလုပ်သမားအဖြစ် ခန့်အပ်ရန်
            ရွေးချယ်ခံရပြီး <span className={classes.dottedUnderline}>
            {convertToMyanmarNumbers(splitDate(data.departureDate).year)}
            </span> ခုနှစ် &nbsp;၊&nbsp;
            <span className={classes.dottedUnderline}>{convertToMyanmarNumbers(splitDate(data.departureDate).month)}</span> လပိုင်း{" "}
            <span className={classes.dottedUnderline}>{convertToMyanmarNumbers(splitDate(data.departureDate).day)}</span> ရက်နေ့ မတိုင်မီ
            လုပ်ငန်းခွင်သို့ ဝင်ရောက်ရမည်ဖြစ်ပါသည်။
            <br />
          </Typography>
          <Typography
            sx={{
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "40px",
              textAlign: "justify",
              mb: 3,
            }}
          >
            ၂။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;သို့ဖြစ်၍  {Number(data.gender) === 1 ? "ကျွန်တော် " : "ကျွန်မ "}&nbsp;
            <span className={classes.dottedUnderline}>{data.nameMm}</span>&nbsp;အား
            OWIC ပြုလုပ်ခွင့်ပြု၍ ဂျပန်နိုင်ငံသို့ အချိန်မီသွားရောက်
            ခွင့်ပြုပါရန် တင်ပြအပ်ပါသည်။
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
              }}
            >
              ပူးတွဲပါ အထောက်အထားများ-
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (က)&nbsp; Offer Letter (or) Demand Letter (မိတ္တူ) ၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (ခ)&nbsp; အလုပ်ရှင် ၊ အလုပ်သမားသဘောတူစာချုပ် (EC) ၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (ဂ)&nbsp; ကာလရှည်နေထိုင်ခွင့် (COE)၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (ဃ)&nbsp; နိုင်ငံကူးလက်မှတ် မိတ္တူ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (င)&nbsp; ဘွဲ့လက်မှတ် (သို့မဟုတ်) ကျွမ်းကျင်မှုအသိအမှတ်ပြုလက်မှတ်၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (စ)&nbsp; ဂျပန်စာအရည်အချင်း၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (ဆ)&nbsp; ပြည်ပအလုပ်အကိုင် လမ်းညွှန်သင်တန်းဆင်း လက်မှတ်၊
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "40px",
                textIndent: 30,
              }}
            >
              (ဇ)&nbsp; ပြည်ဝင်ခွင့် ဗီဇာ (Visa)။
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  width: "50%",
                  mt: 10,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    pt: 3,
                  }}
                >
                  <Typography
                    width="32%"
                    sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                  >
                    လက်မှတ်
                  </Typography>
                  <Box
                    sx={{
                      borderBottom: "2px solid #000",
                      textAlign: "left",
                      flex: "1",
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    ></Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    pt: 3,
                  }}
                >
                  <Typography
                    width="32%"
                    sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                  >
                    အမည်
                  </Typography>
                  <Box
                    sx={{
                      borderBottom: "2px solid #000",
                      textAlign: "left",
                      flex: "1",
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    >{data.nameMm}</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    pt: 3,
                  }}
                >
                  <Typography
                    width="32%"
                    sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                  >
                    ဆက်သွယ်ရန်ဖုန်း၊
                  </Typography>
                  <Box
                    sx={{
                      borderBottom: "2px solid #000",
                      textAlign: "left",
                      flex: "1",
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    >
                      {data.phone}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    pt: 3,
                  }}
                >
                  <Typography
                    width="32%"
                    sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                  >
                    Email
                  </Typography>
                  <Box
                    sx={{
                      borderBottom: "2px solid #000",
                      textAlign: "left",
                      flex: "1",
                    }}
                  >
                    <Typography
                      sx={{ fontFamily: "PdsFont", fontSize: "20px" }}
                    >
                      {data.email}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default NDCoverLetter;
