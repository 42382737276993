import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import Coe7_1_1 from "./Coe7_1_1";
import Coe7_1_2 from "./Coe7_1_2";
import Coe7_1_3 from "./Coe7_1_3";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe7_1 = ({index}) => {
    const classes = useStyles();
    
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>
                        参考様式第１-21号（規則第８条第19号関係）
                    </Typography>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>Ｄ・Ｅ・Ｆ</Typography>
                </Box>
                <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>
                    （日本産業規格Ａ列４）
                </Typography>
            </Box>
            <Typography sx={{
                py: 7,
                textAlign: 'center',
                letterSpacing: 10,
                fontSize: '22px',
                fontFamily: 'Mincho'
            }}>
                技能実習の準備に関し本国で支払った費用の明細書
            </Typography>
            {/* Part 4 */}
            <Coe7_1_1 index={index} />
            {/* Part 5 */}
            <Coe7_1_2 index={index} />
            {/* Part 6 */}
            <Coe7_1_3 index={index} />
        </Box>
    );
}

export default Coe7_1;
