import { MenuItem, Select } from '@mui/material'
import React, { useEffect, useState } from 'react'

const StOrDivSelect = ({stData,setStData,error}) => {

    const [ selectedStateOrDivision,setSelectedStateOrDivision ] = useState('')
    const handleStateOrDiChange = (event)=>{
        setStData(event.target.value);
    }
    useEffect(()=>{
        stData && setSelectedStateOrDivision(stData)
    },[stData])

  return (
        <Select
            error={!!error}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedStateOrDivision}
            onChange={(event)=> handleStateOrDiChange(event)}
        >
            <MenuItem value={'ရန်ကုန်တိုင်းဒေသကြီး'}>ရန်ကုန်တိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'မန္တလေးတိုင်းဒေသကြီး'}>မန္တလေးတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'မကွေးတိုင်းဒေသကြီး'}>မကွေးတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'စစ်ကိုင်းတိုင်းဒေသကြီး'}>စစ်ကိုင်းတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'ဧရာ၀တီတိုင်းဒေသကြီး'}>ဧရာ၀တီတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'နေပြည်တော်တိုင်းဒေသကြီး'}>နေပြည်တော်တိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'ပဲခူးတိုင်းဒေသကြီး'}>ပဲခူးတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'တနင်္သာရီတိုင်းဒေသကြီး'}>တနင်္သာရီတိုင်းဒေသကြီး</MenuItem>
            <MenuItem value={'ကချင်ပြည်နယ်'}>ကချင်ပြည်နယ်</MenuItem>
            <MenuItem value={'ကယားပြည်နယ်'}>ကယားပြည်နယ်</MenuItem>
            <MenuItem value={'ကရင်ပြည်နယ်'}>ကရင်ပြည်နယ်</MenuItem>
            <MenuItem value={'ချင်းပြည်နယ်'}>ချင်းပြည်နယ်</MenuItem>
            <MenuItem value={'မွန်ပြည်နယ်'}>မွန်ပြည်နယ်</MenuItem>
            <MenuItem value={'ရခိုင်ပြည်နယ်'}>ရခိုင်ပြည်နယ်</MenuItem>
            <MenuItem value={'ရှမ်းပြည်နယ်'}>ရှမ်းပြည်နယ်</MenuItem>
        </Select>
  )
}

export default StOrDivSelect
