import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import letterHead from "../../../../assets/images/letter_head.png";
import waterMark from "../../../../assets/images/clover-rm-bg.png";
import { splitDate } from "../../../../utils/splitDate";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { getFullMonthName } from "../../../../utils/getFullMonthName";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
  },
});

const MoeafCoverLetter = ({type}) => {
  const [ preData,setPreData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const { data: singleGroup } = useSelector(state=> state.IntGroup.group)
  const classes = useStyles();
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setPreData(data)
    }
  },[pdfData])

  return (
    <div
      className={classes.screen}
      style={{
        position: "relative",
        paddingBottom: '30px'
      }}
    >
      <Box>
        <img src={letterHead} className="letterHead"></img>
        {
          preData && preData.predeparture &&
          <Box
          sx={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.78), rgba(255, 255, 255, 0.78)), url(${waterMark})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
            backgroundSize: "60%",
            mx: "auto",
            px: 15,
          }}
        >
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "35px" }}
          >
            သို့
          </Typography>
          <Box
            sx={{
              pl: 2,
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              ဥက္ကဌ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              မြန်မာပြည်ပအလုပ်အကိုင်ဝန်ဆောင်မှုလုပ်ငန်းရှင်များအဖွဲ့ချုပ်
            </Typography>
          </Box>
          <Typography
            sx={{
              my: 3,
              textAlign: "end",
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "35px",
            }}
          >
            ရက်စွဲ။&nbsp;&nbsp;&nbsp;။
            {convertToMyanmarNumbers(splitDate(preData.predeparture.date).year)}ခုနှစ်
            &nbsp;{getBurmeseMonthName(getFullMonthName(splitDate(preData.predeparture.date).month))}လ
            &nbsp;(&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;)ရက်
            {/* &nbsp;{convertToMyanmarNumbers(splitDate(preData.predeparture.date).day)}ရက် */}
          </Typography>
          <Typography
            sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "35px" }}
          >
            အကြောင်းအရာ။&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ပြည်ပ
            ထွက်ခွာလမ်းညွှန်အကြိုသင်တန်း (Pre – Departure Course) တက်ရောက်ရန်{" "}
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;လုပ်သားစာရင်းပေးပို့ခြင်း
          </Typography>
          <Typography
            sx={{
              textIndent: "50px",
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "35px",
              textAlign: "justify",
            }}
          >
            အထက်ပါအကြောင်းအရာပါ ကိစ္စနှင့်ပတ်သက်၍ ကျွန်တော် / ကျွန်မတို့၏ Clover
            Mandalay Co., Ltd မှ (ဂျပန်နိုင်ငံ) သို့ သွားရောက်လုပ်ကိုင်မည့်
            အောက်‌ဖော်ပြပါ { type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"} ({convertToMyanmarNumbers(preData.totalWorkers)}) ဦးအား လုပ်ငန်းခွင်သို့
            အချိန်မှီထွက်ခွာနိုင်‌ရေးအတွက် ပြည်ပထွက်ခွာလမ်းညွှန် အကြိုသင်တန်း
            (Pre – Departure Course) အား တက်ရောက်ခွင့်ပြုနိုင်ပါရန်
            တင်ပြလျှောက်ထားအပ်ပါသည်။ မိမိတို့ကုမ္ပဏီမှ တင်ပြသော လုပ်သားစာရင်းမှာ
            ယခုတင်ပြသည့် အလုပ်ခေါ်စာဖြင့် အမှန်တကယ်ထွက်ခွာမည့် လုပ်သားများ
            မှန်ကန်ကြောင်း ဝန်ခံကတိ ပြုပါသည်။ အကယ်၍ မမှန်မကန်တင်ပြခဲ့ပါက
            ကုမ္ပဏီအား အရေးယူခြင်းခံရမည်ကို သိရှိနားလည်ပါသည်။
          </Typography>
          <Typography
            sx={{
              textDecoration: "underline",
              mt: 2,
              fontFamily: "PdsFont",
              fontSize: "20px",
              lineHeight: "35px",
            }}
          >
            ပူးတွဲတင်ပြသည့် စာရွက်စာတမ်းများ
          </Typography>
          <Box
            sx={{
              pl: 2,
              width: "80%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (က)&nbsp;&nbsp;ဦးစီးဌာန(ရုံးချုပ်)မှ သက်ဆိုင်ရာအေဂျင်စီသို့
              စေလွှတ်ရေးလုပ်ငန်းစဥ်များ <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ဆောင်ရွက်နိုင်ပြီဖြစ်ကြောင်း
              ခွင့်ပြုသည့်စာ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (ခ)&nbsp;&nbsp;&nbsp;Demand Letter
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (ဂ)&nbsp;&nbsp;&nbsp;နိုင်ငံကူးလက်မှတ်မိတ္တူ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (ဃ)&nbsp;&nbsp;COE၊ VISA မိတ္တူ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (င)&nbsp;&nbsp;&nbsp;သက်ဆိုင်ရာ
              အလုပ်သမားညွှန်ကြားရေးဦးစီးဌာန(မြို့နယ်ရုံး) တာဝန်ခံအရာရှိမှ
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;အတည်ပြုလက်မှတ်
              ရေးထိုးပြီးသည့် အလုပ်ရှင် အလုပ်သမားသဘောတူစာချုပ်မိတ္တူ
            </Typography>
            <Typography
              sx={{
                fontFamily: "PdsFont",
                fontSize: "20px",
                lineHeight: "35px",
              }}
            >
              (စ)&nbsp;&nbsp;&nbsp;တက်ရောက်မည့်အလုပ်သမားစာရင်း (Hard Copy & Soft
              Copy excel)
            </Typography>
            {
              Number(singleGroup.type) === 1 &&
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                (ဆ)&nbsp;&nbsp;MOEAA ထောက်ခံစာ
              </Typography>
            }
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              pr: "50px",
            }}
          >
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  mt: 5,
                  mb: 15,
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                လေးစားစွာဖြင့်
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                ချစ်စုဝေ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                မန်နေးဂျင်းဒါရိုက်တာ
              </Typography>
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "35px",
                }}
              >
                Clover Mandalay Co., Ltd
              </Typography>
            </Box>
          </Box>
        </Box>
        }
      </Box>
    </div>
  );
};

export default MoeafCoverLetter;
