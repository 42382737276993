import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetGetCVById } from "../../slices/backOffice/cvFromSlice";
import theme from "../../utils/theme";

const BackButton = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (type === "resetGetCVById") {
      dispatch(resetGetCVById());
    }
    navigate(-1);
  };
  return (
    <Box
      onClick={() => handleOnClick()}
      sx={{ display: "flex", alignItem: "center", cursor: "pointer" }}
    >
      <KeyboardBackspaceIcon
        sx={{ color: theme.palette.primary.main, mr: 2 }}
      />
      <Typography sx={{ color: theme.palette.primary.main }}>Back</Typography>
    </Box>
  );
};

export default BackButton;
