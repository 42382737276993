import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Paper, Tab } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Children from "../../../components/backOffice/cvForm/swDetail/Children";
import EduInfo from "../../../components/backOffice/cvForm/titsswDetail/EduInfo";
import FamilyMember from "../../../components/backOffice/cvForm/titsswDetail/FamilyMember";
import OtherEdu from "../../../components/backOffice/cvForm/titsswDetail/OtherEdu";
import PersonalInfo from "../../../components/backOffice/cvForm/titsswDetail/PersonalInfo";
import WorkExp from "../../../components/backOffice/cvForm/titsswDetail/WorkExp";
import BackButton from "../../../components/utils/BackButton";
import Loading from "../../../components/utils/Loading";
import { getCvById } from "../../../slices/backOffice/cvFromSlice";

const CvDetailTitssw = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const cvData = useSelector((state) => state.CvForm.cv);
  const cvLoading = useSelector((state) => state.CvForm.loading);
  const [value, setValue] = useState("1");
  const [cv, setCv] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (cvData) {
      setCv(cvData);
    }
  }, [cvData]);

  useEffect(() => {
    if (value === "7") {
      navigate(-1);
    }
  }, [value, navigate]);

  useEffect(() => {
    const getData = async () => {
      await dispatch(getCvById({ type: "titssw", id: id }));
    };

    getData();
  }, [dispatch, id]);

  return (
    <Paper
      sx={{
        width: "100%",
        overflow: "hidden",
        px: 1,
        minHeight: "100vh",
        borderRadius: 0,
      }}
    >
      {cvLoading && <Loading />}
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={<BackButton type="resetGetCVById" />} value="7"></Tab>
              <Tab label="Personal Info" value="1" />
              <Tab label="Education" value="2" />
              <Tab label="Work Experiences" value="3" />
              <Tab label="Family" value="4" />
              <Tab label="Other Edu" value="5" />
              <Tab label="Children" value="6" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {cv && <PersonalInfo cv={cv}></PersonalInfo>}
          </TabPanel>
          <TabPanel value="2">{cv && <EduInfo cv={cv}></EduInfo>}</TabPanel>
          <TabPanel value="3">{cv && <WorkExp cv={cv}></WorkExp>}</TabPanel>
          <TabPanel value="4">
            {cv && <FamilyMember cv={cv}></FamilyMember>}
          </TabPanel>
          <TabPanel value="5">{cv && <OtherEdu cv={cv}></OtherEdu>}</TabPanel>
          <TabPanel value="6">{cv && <Children cv={cv}></Children>}</TabPanel>
        </TabContext>
      </Box>
    </Paper>
  );
};

export default CvDetailTitssw;
