import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { splitDate } from "../../../utils/splitDate";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
    text: {
        textIndent: '50px',
        lineHeight: '50px',
        fontSize: '22px',
        textAlign: 'justify',
    },
    mincho: {
        fontFamily: 'Mincho',
    },
    pdsFont: {
        fontFamily: 'PdsFont',
    },
    dateBox: {
        display: 'flex',
        justifyContent: 'end',
        paddingBottom: '40px',
        textAlign: 'end',
    },
    dateColumn: {
        width: '70px',
    },
    signatureBox: {
        display: 'flex',
        alignItems: 'center',
        width: "500px",
        justifyContent: 'space-between',
        marginLeft: 'auto',
        marginTop: '30px'
    },
    signatureLine: {
        width: '200px',
    },
});

const Coe6_2 = ({index}) => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState([])
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[index]
        setMemberData(data)
      }
    },[printData, index])

    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography className={`${classes.text} ${classes.mincho}`}>
                日本国で技能実習を行うに当たり、私や私と関係のある人が、誰かに金銭などの財産を管理されることとはなっていません。また、今後管理される予定もありません。
            </Typography>
            <Typography className={`${classes.text} ${classes.pdsFont}`}>
                ဂျပန်နိုင်ငံတွင် နည်းပညာလက်တွေ့သင်ယူခြင်းဆောင်ရွက်ရန် ကျွန်ုပ်နှင့်ကျွန်ုပ်၏သက်ဆိုင်သူများသည် တစ်ဦး တစ်ယောက်၏ ငွေကြေးစသည့် ပစ္စည်းဥစ္စာခြယ်လှယ်ခြင်းမရှိပါ။ တစ်ဖန် နောက်နောင်တွင် ချုပ်ကိုင်ခံရရန်အစီအစဉ် လည်း မရှိပါ။
            </Typography>

            <Typography className={`${classes.text} ${classes.mincho}`}>
                日本国で技能実習を行うに当たり、私や私と関係のある人が、誰かと、所定の技能実習を計画どおり修了しなかったなど技能実習に係る契約の不履行があった場合に違約金を支払う契約を結んでいません。また、今後結ぶ予定もありません。
            </Typography>
            <Typography className={`${classes.text} ${classes.pdsFont}`}>
                ဂျပန်နိုင်ငံတွင် နည်းပညာလက်တွေ့သင်ယူခြင်းဆောင်ရွက်ရန် ကျွန်ုပ်နှင့်ကျွန်ုပ်၏သက်ဆိုင်သူများသည် သတ်မှတ်ထား သော နည်းပညာလက်တွေ့သင်ယူခြင်းကို စီမံကိန်းအတိုင်း မပြီးမြောက်ခြင်းစသည့် နည်းပညာလက်တွေ့သင်ယူခြင်းနှင့် စပ်လျဉ်းသည့် စာချုပ်အပေါ်ပျက်ကွက်မှုများရှိခဲ့ပါက ချိုးဖောက်ကြေးငွေပေးချေပါမည်ဟူသောစာချုပ်ကို တစ်ဦး တစ်ယောက်နှင့်မျှ ချုပ်ဆိုထားခြင်းမရှိပါ။ တစ်ဖန် နောင်တွင် ချုပ်ဆိုရန်အစီအစဉ်လည်းမရှိပါ။
            </Typography>

            <Box sx={{ py: '30px' }}>
                <Typography className={`${classes.text} ${classes.mincho}`}>
                    上記の記載内容は、事実と相違ありません。
                </Typography>
                <Typography className={`${classes.text} ${classes.pdsFont}`}>
                    အထက်ဖော်ပြပါအကြောင်းအရာများသည် အမှန်တကယ်နှင့် အမှားအယွင်းမရှိပါ။
                </Typography>
            </Box>

            <Box className={classes.dateBox}>
                <Box>
                    <Typography className={`${classes.text} ${classes.mincho}`}>{splitDate(memberData.coeDcDate).year} 年</Typography>
                    <Typography className={`${classes.text} ${classes.mincho}`}>နှစ်</Typography>
                </Box>
                <Box>
                    <Typography className={`${classes.text} ${classes.mincho}`}>{splitDate(memberData.coeDcDate).month} 月</Typography>
                    <Typography className={`${classes.text} ${classes.mincho}`}>လ</Typography>
                </Box>
                <Box>
                    <Typography className={`${classes.text} ${classes.mincho}`}>{splitDate(memberData.coeDcDate).day} 日</Typography>
                    <Typography className={`${classes.text} ${classes.mincho}`}>ရက်</Typography>
                </Box>
            </Box>

            <Box className={classes.signatureBox}>
                <Typography className={`${classes.text} ${classes.mincho}`}>
                    技能実習生の署名
                </Typography>
                <hr className={classes.signatureLine} />
            </Box>

            <Box sx={{ width: 400, margin: '20px 0px 0px auto' }}>
                <Typography className={`${classes.pdsFont}`} sx={{ fontSize: '22px'}}>
                    နည်းပညာလက်တွေ့သင်တန်းသား၏လက်မှတ်
                </Typography>
            </Box>
        </Box>
    );
}

export default Coe6_2;
