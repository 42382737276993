import React, { useState, useEffect, Fragment } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import theme from '../../../utils/theme'
import { Box, Divider, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import NrcForFill from '../../../components/utils/NrcForFill';
import { convertToEnNrc } from 'mm-nrc';
import Loading from '../../../components/utils/Loading';
import { getGroupById } from '../../../slices/backOffice/interviewGpSlice';
import { fillData, getOldFillData, resetFillData, updateFillData } from '../../../slices/backOffice/fillDataSlice';
import { useLocation, useParams } from 'react-router-dom';
import Date from '../../../components/main/Date';
import StOrDivSelect from '../../../components/utils/StOrDivSelect';

const fillDataSchema = Yup.object().shape({
    name_mm: Yup.string().required("Name in burmese is required"),
    passport: Yup.string().required("Passport is required"),
    nrc_mm: Yup.string().required("NRC is required"),
    father_name_eng : Yup.string().required("Father name is required"),
    father_name_mm : Yup.string().required("Father name in burmese is required"),
    mother_name_eng : Yup.string().required("Father name is required"),
    mother_name_mm : Yup.string().required("Father name in burmese is required"),
    ethnicity_religion_mm: Yup.string().required("Ethnicity and region in burmese is required"),
    state_or_division_address: Yup.string().required("State or division is required"),

    district_mm: Yup.string().nullable(),
    township_mm: Yup.string().nullable(),
    ward_or_village_mm: Yup.string().nullable(),
    road_mm: Yup.string().nullable(),
    address_no_mm: Yup.string().nullable(),

    district_eng: Yup.string().nullable(),
    township_eng: Yup.string().nullable(),
    ward_or_village_eng: Yup.string().nullable(),
    road_eng: Yup.string().nullable(),
    address_no_eng: Yup.string().nullable(),

    hometown_mm: Yup.string().required("Hometown is required"),
    edu_status: Yup.string().required("Education status is required"),
    promient_mark: Yup.string().required("Promient mark in burmese is required"),
    inheritance_name_mm: Yup.string().required("Inheritance name in burmese is required"),
    inheritance_relationship_mm: Yup.string().required("Inheritance relationship in burmese is required"),
    inheritance_phone: Yup.string().matches(/^\d{9,11}$/, 'Please fill a valid phone number').required("Inheritance's phone is required"),
    inheritance_state_or_division_address: Yup.string().required("Inheritance's address (State or division) is required"),
    inheritance_districts: Yup.string().nullable(),
    inheritance_townships: Yup.string().nullable(),
    inheritance_ward_or_village: Yup.string().nullable(),
    inheritance_road: Yup.string().nullable(),
    inheritance_address_no: Yup.string().nullable(),
    inheritance_nrc_mm: Yup.string().required('NRC is required'),
    spouse_name:  Yup.string().nullable(),
    spouse_age:  Yup.string().nullable(),
    spouse_address:  Yup.string().nullable(),
    passport_date: Yup.string().required('Passport issued date is required')
})

const FillData = ({cvId,isOpen,setIsOpen})=>{

    const dispatch = useDispatch()
    const [ date,setDate ] = useState(null)
    const { id } = useParams()
    const [open, setOpen] = useState(isOpen);
    const [nrcData, setNrcData] = useState(null);
    const [inhNrcData, setInhNrcData] = useState(null);
    const loading = useSelector(state=> state.FillData.loading)
    const cvData = useSelector(state=> state.CvForm.cv)
    const cvDataLoading = useSelector(state=> state.CvForm.loading)
    const oldFillData = useSelector(state=> state.FillData.getOldFillData)
    const [ selectedStateOrDivision, setSelectedStateOrDivision ] = useState('')
    const [ selectedInhStateOrDivision, setSelectedInhStateOrDivision ] = useState('')
    const fillType = useLocation().pathname.includes('group-edit-titssw') ? "titssw" : "sw";

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        reset
    } = useForm({
        resolver: yupResolver(fillDataSchema),
    });

    const handleClose = async () => {
        reset()
        await dispatch(resetFillData())
        setInhNrcData(null)
        setNrcData(null)
        setDate(null)
        setSelectedStateOrDivision('')
        setSelectedInhStateOrDivision('')
        setOpen(false);
        setIsOpen(false)
    };

    const handleOnSubmit = async (data)=>{
        const newData = {
        passport: data.passport,
        nrc_eng: fillType === 'titssw' ? convertToEnNrc(data.nrc_mm) : null,
        nrc_mm: data.nrc_mm,
        name_mm: data.name_mm,
        state_or_division_address: data.state_or_division_address,

        district_mm: data.district_mm,
        township_mm: data.township_mm,
        ward_or_village_mm: data.ward_or_village_mm,
        road_mm: data.road_mm,
        address_no_mm: data.address_no_mm,

        district_eng: data.district_eng,
        township_eng: data.township_eng,
        ward_or_village_eng: data.ward_or_village_eng,
        road_eng: data.road_eng,
        address_no_eng: data.address_no_eng,

        father_name_mm: data.father_name_mm,
        father_name_eng: data.father_name_eng,
        mother_name_mm: data.mother_name_mm,
        mother_name_eng: data.mother_name_eng,
        hometown_mm: data.hometown_mm,
        ethnicity_religion_mm: data.ethnicity_religion_mm,
        promient_mark: data.promient_mark,
        inheritance_name_mm: data.inheritance_name_mm,
        inheritance_relationship_mm: data.inheritance_relationship_mm,
        inheritance_nrc_mm: data.inheritance_nrc_mm,
        inheritance_phone: data.inheritance_phone,
        inheritance_state_or_division_address: data.inheritance_state_or_division_address,
        inheritance_districts: data.inheritance_districts,
        inheritance_townships: data.inheritance_townships,
        inheritance_ward_or_village: data.inheritance_ward_or_village,
        inheritance_road: data.inheritance_road,
        inheritance_address_no: data.inheritance_address_no,
        edu_status: data.edu_status,
        spouse_name: data.spouse_name,
        spouse_age: data.spouse_age,
        spouse_address: data.spouse_address,
        passport_date: data.passport_date
    }
    oldFillData ? await dispatch(updateFillData({ type: fillType, data: newData, id: Number(cvId)})) : await dispatch(fillData({ type: fillType, data: newData, id: Number(cvId)}));;
    await dispatch(getGroupById(id))
    setNrcData(null)
    setInhNrcData(null)
    handleClose()
  }

  useEffect(()=>{
    date && setValue('passport_date', date)
  },[date])

  useEffect(()=>{
    if(nrcData){
        setValue('nrc_mm',nrcData);
    }
  },[nrcData])

  useEffect(()=>{
    if(inhNrcData){
        setValue('inheritance_nrc_mm',inhNrcData)
    }
  },[inhNrcData])

  useEffect(()=>{
    if(selectedStateOrDivision){
        setValue('state_or_division_address', selectedStateOrDivision)
        setSelectedStateOrDivision(selectedStateOrDivision);
    }
  },[selectedStateOrDivision])

  useEffect(()=>{
    if(selectedInhStateOrDivision){
        setValue('inheritance_state_or_division_address', selectedInhStateOrDivision)
        setSelectedInhStateOrDivision(selectedInhStateOrDivision);
    }
  },[selectedInhStateOrDivision])

  useEffect(()=>{
    const fetchData = async () => {
        if(oldFillData && oldFillData?.passport){
            await
            setValue('edu_status', oldFillData.edu_status)
            setValue('ethnicity_religion_mm', oldFillData.ethnicity_religion_mm)
            setValue('father_name_eng', oldFillData.father_name_eng)
            setValue('father_name_mm', oldFillData.father_name_mm)
            setValue('mother_name_eng', oldFillData.mother_name_eng)
            setValue('mother_name_mm', oldFillData.mother_name_mm)
            setValue('spouse_name', oldFillData.spouse_name)
            setValue('spouse_age', oldFillData.spouse_age)
            setValue('spouse_address', oldFillData.spouse_address)
            setValue('name_mm', oldFillData.name_mm)
            setValue('nrc_mm', oldFillData.nrc_mm)
            setValue('passport', oldFillData.passport)
            setValue('state_or_division_address', oldFillData.state_or_division_address)

            setValue('district_mm', oldFillData.district_mm)
            setValue('township_mm', oldFillData.township_mm)
            setValue('ward_or_village_mm', oldFillData.ward_or_village_mm)
            setValue('road_mm', oldFillData.road_mm)
            setValue('address_no_mm', oldFillData.address_no_mm)

            setValue('district_eng', oldFillData.district_eng)
            setValue('township_eng', oldFillData.township_eng)
            setValue('ward_or_village_eng', oldFillData.ward_or_village_eng)
            setValue('road_eng', oldFillData.road_eng)
            setValue('address_no_eng', oldFillData.address_no_eng)

            setValue('hometown_mm', oldFillData.hometown_mm)
            setValue('promient_mark', oldFillData.promient_mark)
            setValue('inheritance_name_mm', oldFillData.inheritance_name_mm)
            setValue('inheritance_relationship_mm', oldFillData.inheritance_relationship_mm)
            setValue('inheritance_address_no', oldFillData.inheritance_address_no)
            setValue('inheritance_districts', oldFillData.inheritance_districts)
            setValue('inheritance_townships', oldFillData.inheritance_townships)
            setValue('inheritance_road', oldFillData.inheritance_road)
            setValue('inheritance_ward_or_village', oldFillData.inheritance_ward_or_village)
            setValue('inheritance_phone', oldFillData.inheritance_phone)
            setValue('passport_date', oldFillData.passport_date)
            setValue('inheritance_state_or_division_address', oldFillData.inheritance_state_or_division_address)
            setValue('inheritance_nrc_mm', oldFillData.inheritance_nrc_mm)
            setSelectedStateOrDivision(oldFillData.state_or_division_address)
            setSelectedInhStateOrDivision(oldFillData.inheritance_state_or_division_address)
            setNrcData(oldFillData.nrc_mm)
            setInhNrcData(oldFillData.inheritance_nrc_mm)
            setDate(oldFillData.passport_date)
        }
    };
    fetchData(); 
  },[oldFillData])

  useEffect(()=>{
    setOpen(isOpen)
    
  },[isOpen])

  useEffect(()=>{
    cvData && dispatch(getOldFillData(cvData))
  },[cvData])

  return (
    <Fragment >
        {
            loading || cvDataLoading && <Loading/>
        }
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
            <DialogTitle id="alert-dialog-title">
                Necessary Data
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" sx={{ mb: 2 }}>
                    After passing the interview, fill in the required fields to continue the documentation process.
                </DialogContentText>
                <form autoComplete='off' onSubmit={handleSubmit(handleOnSubmit)}>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Name(Myanmar)</InputLabel>
                            <TextField {...register('name_mm')} error={!!errors?.name_mm} helperText={errors?.name_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Passport</InputLabel>
                            <TextField {...register('passport')} error={!!errors?.passport} helperText={errors?.passport?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={{ mb: 1 }}>NRC</InputLabel>
                            <NrcForFill
                                data={nrcData}
                                setData={setNrcData}
                                error={errors?.nrc_eng}
                            ></NrcForFill>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Father Name(English)</InputLabel>
                            <TextField {...register('father_name_eng')} error={!!errors?.father_name_eng } helperText={errors?.father_name_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Father Name(Myanmar)</InputLabel>
                            <TextField {...register('father_name_mm')} error={!!errors?.father_name_mm} helperText={errors?.father_name_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Mother Name(English)</InputLabel>
                            <TextField {...register('mother_name_eng')} error={!!errors?.mother_name_eng} helperText={errors?.mother_name_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Mother Name(Myanmar)</InputLabel>
                            <TextField {...register('mother_name_mm')} error={!!errors?.mother_name_mm} helperText={errors?.mother_name_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Ethnicity and Religion(Myanmar)</InputLabel>
                            <TextField {...register('ethnicity_religion_mm')} error={!!errors?.ethnicity_religion_mm} helperText={errors?.ethnicity_religion_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>ပညာအရည်ချင်း(Myanmar)</InputLabel>
                            <TextField {...register('edu_status')} error={!!errors?.edu_status} helperText={errors?.edu_status?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>State Or Division(Myanmar)</InputLabel>
                            <FormControl fullWidth>
                               <StOrDivSelect stData={selectedStateOrDivision} setStData={setSelectedStateOrDivision} error={errors?.state_or_division_address}></StOrDivSelect>
                            </FormControl>
                            { errors?.state_or_division_address && <FormHelperText sx={{ color: theme.palette.danger.main }}>Select an option</FormHelperText>}
                        </Grid>
                        {/* Address Myanmar */}
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>District(Myanmar)</InputLabel>
                            <TextField {...register('district_mm')} error={!!errors?.district_mm} helperText={errors?.district_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Township(Myanmar)</InputLabel>
                            <TextField {...register('township_mm')} error={!!errors?.township_mm} helperText={errors?.township_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Ward Or Village(Myanmar)</InputLabel>
                            <TextField {...register('ward_or_village_mm')} error={!!errors?.ward_or_village_mm} helperText={errors?.ward_or_village_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Road(Myanmar)</InputLabel>
                            <TextField {...register('road_mm')} error={!!errors?.road_mm} helperText={errors?.road_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>House No(Myanmar)</InputLabel>
                            <TextField {...register('address_no_mm')} error={!!errors?.address_no_mm} helperText={errors?.address_no_mm?.message} fullWidth></TextField>
                        </Grid>
                        {/* Address English  */}
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>District(English)</InputLabel>
                            <TextField {...register('district_eng')} error={!!errors?.district_eng} helperText={errors?.district_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Township(English)</InputLabel>
                            <TextField {...register('township_eng')} error={!!errors?.township_eng} helperText={errors?.township_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Ward Or Village(English)</InputLabel>
                            <TextField {...register('ward_or_village_eng')} error={!!errors?.ward_or_village_eng} helperText={errors?.ward_or_village_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Road(English)</InputLabel>
                            <TextField {...register('road_eng')} error={!!errors?.road_eng} helperText={errors?.road_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>House No(English)</InputLabel>
                            <TextField {...register('address_no_eng')} error={!!errors?.address_no_eng} helperText={errors?.address_no_eng?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Hometown(Myanmar)</InputLabel>
                            <TextField {...register('hometown_mm')} error={!!errors?.hometown_mm} helperText={errors?.hometown_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Promient Mark(Myanmar)</InputLabel>
                            <TextField {...register('promient_mark')} error={!!errors?.promient_mark} helperText={errors?.promient_mark?.message} fullWidth></TextField>
                        </Grid>                    
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Spouse Name(Myanmar)</InputLabel>
                            <TextField {...register('spouse_name')} error={!!errors?.spouse_name} helperText={errors?.spouse_name?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Spouse Age(Myanmar)</InputLabel>
                            <Box sx={{ pb: 1 }}></Box>
                            <TextField {...register('spouse_age')} error={!!errors?.spouse_age} helperText={errors?.spouse_age?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ pt: 1 }}>
                                <InputLabel sx={{ mb: 1 }}>Spouse Address(Myanmar)</InputLabel>
                                <TextField {...register('spouse_address')} error={!!errors?.spouse_address} helperText={errors?.spouse_address?.message} fullWidth></TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Passport Issued Date</InputLabel>
                            <Date dateVal={date} setDate={setDate} error={errors?.passport_date}></Date>
                        </Grid>
                    </Grid>
                    <Divider sx={{ my: 3 }}>Inheritance Data</Divider>
                    <Grid container spacing={2} columns={12}>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Name (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_name_mm')} error={!!errors?.inheritance_name_mm} helperText={errors?.inheritance_name_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Relationship (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_relationship_mm')} error={!!errors?.inheritance_relationship_mm} helperText={errors?.inheritance_relationship_mm?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Phone</InputLabel>
                            <TextField {...register('inheritance_phone')} error={!!errors?.inheritance_phone} helperText={errors?.inheritance_phone?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance State Or Division (Myanmar)</InputLabel>
                            <FormControl fullWidth>
                               <StOrDivSelect stData={selectedInhStateOrDivision} setStData={setSelectedInhStateOrDivision} error={errors?.inheritance_state_or_division_address}></StOrDivSelect>
                            </FormControl>
                            { errors?.inheritance_state_or_division_address && <FormHelperText sx={{ color: theme.palette.danger.main }}>Select an option</FormHelperText>}
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance District (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_districts')} error={!!errors?.inheritance_districts} helperText={errors?.inheritance_districts?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Township (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_townships')} error={!!errors?.inheritance_townships} helperText={errors?.inheritance_townships?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Ward Or Village (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_ward_or_village')} error={!!errors?.inheritance_ward_or_village} helperText={errors?.inheritance_ward_or_village?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance Road (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_road')} error={!!errors?.inheritance_road} helperText={errors?.inheritance_road?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance House No (Myanmar)</InputLabel>
                            <TextField {...register('inheritance_address_no')} error={!!errors?.inheritance_address_no} helperText={errors?.inheritance_address_no?.message} fullWidth></TextField>
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel sx={{ mb: 1 }}>Inheritance NRC</InputLabel>
                            <NrcForFill
                                data={inhNrcData}
                                setData={setInhNrcData}
                                error={errors?.inheritance_nrc_mm}
                            ></NrcForFill>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', mt: 3 }}>
                        <Button type='button' onClick={handleClose} sx={{ color: theme.palette.common.black }}>Cancel</Button>
                        <Button type='submit' autoFocus sx={{ mx: 2 }}>
                            {oldFillData ? "Update" : "Submit"}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
      </Dialog>
    </Fragment>
  );
}

export default FillData