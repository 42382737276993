import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { useState } from "react";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -250px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "10px 15px",
    border: "2px solid #000",
  },
});

const AgencyList = ({type}) => {
  const [ dmData,setDmData ] = useState(null)
  const pdfData = useSelector(state=> state.PrintData.data)
  const classes = useStyles();
  
  useEffect(()=>{
    if(pdfData){
      const { data } = pdfData
      setDmData(data)
    }
  },[pdfData])

  return (
    <div className={classes.screen}>
      {
        dmData &&
        <>
          <div style={{ height: "400px", width: "100%" }}></div>
          <Box className={classes.rotatedBox}>
            <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', textAlign: 'center', lineHeight: '40px', mb: 3, fontWeight: 'bold' }}>
              ဂျပန်နိုင်ငံသို့ နည်းပညာ { type === 'tit' ?"အလုပ်သင်သင်တန်းသား" : "အထူးကျွမ်းကျင်လုပ်သား"} စေလွှတ်ခွင့်
              Demand Letter တင်ပြသည့် <br />
              ပြည်ပအလုပ်အကိုင်အကျိုးဆောင်လိုင်စင်ရအေဂျင်စီများစာရင်း
            </Typography>
              <table className="custom-table">
              <tbody>
                <tr>
                  <td className={classes.tableCell} rowSpan={2}>
                    စဉ်
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    ရက်စွဲ
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    ပြည်ပအလုပ်အကိုင်အကျိုးဆောင် လိုင်စင်ရအေဂျင်စီ အမည်
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    ဂျပန်နိုင်ငံရှိ ကုမ္ပဏီအမည်
                  </td>
                  <td className={classes.tableCell} colSpan={3} style={{ whiteSpace: 'nowrap' }}>
                    အလုပ်သမားဦးရေ
                  </td>
                  <td className={classes.tableCell} rowSpan={2}>
                    အလုပ်သမားများလုပ်ကိုင်ရမည့် ဂျပန်ကုမ္ပဏီလိပ်စာ
                  </td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>ကျား</td>
                  <td className={classes.tableCell}>မ</td>
                  <td className={classes.tableCell}>ပေါင်း</td>
                </tr>
                <tr>
                  <td className={classes.tableCell}>၁</td>
                  <td className={classes.tableCell} style={{ whiteSpace: 'nowrap' }}>{dmData.demandLetterDate}</td>
                  <td className={classes.tableCell} style={{ whiteSpace: 'nowrap' }}>Clover Mandalay Co., Ltd</td>
                  <td className={classes.tableCell}>
                    Organization: {dmData.organization.nameEng}
                    <br></br>
                    Company: {dmData.company.nameEng}
                  </td>
                  <td className={classes.tableCell}>{ convertToMyanmarNumbers(dmData.numberOfWorkers.male)}</td>
                  <td className={classes.tableCell}>{convertToMyanmarNumbers(dmData.numberOfWorkers.female)}</td>
                  <td className={classes.tableCell}>{convertToMyanmarNumbers(dmData.numberOfWorkers.total)}</td>
                  <td className={classes.tableCell}>
                    {/* Demand Letter တွင် ဖော်ပြထားသည့် (ကြီးကြပ်အဖွဲ့အစည်း လိပ်စာ၊
                    Post Code၊ ဖုန်း၊ Fax No အပြည့်အစုံ) (ဂျပန်ကုမ္ပဏီ လိပ်စာ၊ ဖုန်း
                    နံပါတ်၊ Fax No အပြည့်အစုံ) */}
                    Organization: {`${dmData.organization.addressEng}, ${dmData.organization.postcode}, ${dmData.organization.phone}, ${dmData.organization.fax}`}
                    <br></br>
                    Company: {`${dmData.company.addressEng}, ${dmData.company.phone}, ${dmData.company.fax}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </Box>
          <div style={{ height: "400px", width: "100%" }}></div>
        </>
      }
    </div>
  );
};

export default AgencyList;
