import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, FormHelperText, Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import CollectionsIcon from '@mui/icons-material/Collections';
import CreateLayout from '../../../components/layouts/CreateLayout';
import { updateImg } from '../../../slices/cvForm/ImageSlice';
import { getCvById } from '../../../slices/backOffice/cvFromSlice';
import TitsswPdf from '../../back_office/pdfs/titssw/TitsswPdf';
import SwPdf from '../../back_office/pdfs/sw/SwPdf';

const validationSchema = Yup.object().shape({
  pr: Yup.string().nullable(),
  photo_data: Yup.string().nullable(),
  qr_photo_data: Yup.string().nullable(),
});

const FieldUpload = ({ label, accept, onChange, error, helperText }) => (
  <Box
    sx={{
      width: '100%',
      height: '200px',
      border: '1px dotted #666666',
      borderRadius: 1,
      marginY: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <CollectionsIcon sx={{ color: '#666666' }} />
    <Button component="label" variant="text" sx={{ marginY: 1 }}>
      {label ? label : `Upload ${helperText}`}
      <input type="file" accept={accept} onChange={onChange} style={{ display: 'none' }} />
    </Button>
    <Typography sx={{ marginBottom: 1, fontSize: '14px' }} color={'#666666'} variant="small">
      {helperText}
    </Typography>
    <FormHelperText error={!!error}>{error?.message}</FormHelperText>
  </Box>
);

const CreateCvStep9 = () => {

  const dispatch = useDispatch()
  const profileData = useSelector((state) => state.User?.profile?.data);
  const [photoLabel, setPhotoLabel] = useState(null);
  const [qrLabel, setQrLabel] = useState(null);
  const navigate = useNavigate();
  const reader = new FileReader();
  const [ pdfOpen,setPdfOpen ] = useState(false)
  const [ cvId,setCvId ] = useState(null)

  const { handleSubmit, register, formState: { errors }, setValue, reset } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // const getCvData = async (type,cvId) => {
  //   await dispatch(getCvById({ type: type, id: cvId }));
  // };

  const handleDownloadPdf = async ()=>{
    setPdfOpen(true)
  }

  const handleUpload = (e, key, labelState) => {
    const file = e.target.files[0];
    convertToBase64String(file, key, labelState);
  };

  const convertToBase64String = (file, key, labelState) => {
    if (!file) return;
    labelState(file?.name);
    reader.onloadend = () => {
      const base64String = reader.result;
      setValue(key, base64String);
    };
    reader.readAsDataURL(file);
  };

  const handleCancel = () => {
    navigate('/')
  };

  const handleFormSubmit = async (data) => {
    if (!profileData) return;  
    const newData = {
      pr: data.pr === "" ? null : data.pr,
      photo_data: !data.photo_data ? null : data.photo_data,
      qr_photo_data: !data.qr_photo_data ? null : data.qr_photo_data
    };
    await dispatch(updateImg({ method: 'put', data: newData, type: profileData.titssw_id ? 'titssw' : 'sw' ,id: profileData.titssw_id || profileData.sw_id}))
    setPhotoLabel(null);
    setQrLabel(null);
    reset();
  };

  useEffect(()=>{
    if(profileData){
      const cvId = profileData?.titssw_id || profileData?.sw_id
      setCvId(cvId)
      // getCvData(profileData?.titssw_id ? 'titssw' : 'sw', cvId)
    }
  },[profileData])
  
  return (
    <CreateLayout title="" submTitle="">
      {
        profileData && profileData?.titssw_id ?
        <TitsswPdf isOpen={pdfOpen} setIsOpen={setPdfOpen} cvId={cvId}></TitsswPdf>
        :
        <SwPdf isOpen={pdfOpen} setIsOpen={setPdfOpen} cvId={cvId}></SwPdf>
      }
      <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
        <Box sx={{ width: '350px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography>Since your cv information is already filled in, you can edit pr and photos.</Typography>
          <Button onClick={() => handleDownloadPdf()} variant='text' sx={{ my: 2 }}>Get your cv here</Button>
          <TextField
            placeholder='Note : ကျွန်တော်ငယ်ငယ်လေးကတည်းကလယ်ယာလုပ်ငန်းနဲ့ပတ်သတ်တဲ့အတွေ့ကြုံရှိပါသည် မိဘတွေမအားသည့်အခါတွင်ကျွန်တော်ကပဲလယ်ယာလုပ်ငန်းကိုဦးဆောင်လုပ်ကိုင်ခဲ့ရပါသည် လယ်ယာလုပ်ငန်းဆိုသည့်အတိုင်းပင်ပင်ပန်းပန်းလုပ်ကိုင်ရသောကြောင့်ပင်ပန်းဒဏ်အမောဒဏ်များကိုလည်းခံနိုင်ရည်စွမ်းရှိပါသည် လယ်ယာလုပ်ငန်းသည်လည်းလူအများနဲ့ပူပေါင်းလုပ်ကိုင်ရသောကြောင့်ပြောဆိုဆက်ဆံရေးမှာလည်းကျွမ်းကျွမ်းကျင်ကျင်ပြောဆိုနိင်သောအားသာချက်ရှိပါသည် အလုပ်ကိုလည်းကြိုးကြိုးစားစားလုပ်ဆောင်နိုင်ပြီးအလုပ်၏စည်းကမ်းများကိုလည်းတသွေမတိမ်းလိုက်နာနိုင်ပါသည် ကျွန်တော်ကအမြင့်တက်ရမှာလည်းမကြောက်သောကြောင့်ငြမ်းစင်လုပ်ငန်းသည်ကျွန်တော်နဲ့အကိုက်ညီဆုံးအလုပ်ဖြစ်မယ်လိုယုံကြည်ပါတယ်။'
            error={!!errors?.pr}
            helperText={errors?.pr?.message || ''}
            {...register('pr')}
            label="PR"
            multiline />
          <FieldUpload
            label={photoLabel}
            accept="image/*"
            onChange={(e) => handleUpload(e, 'photo_data', setPhotoLabel)}
            error={errors?.photo_data}
            helperText="3*4 cm ဖြစ်ရမည်"
          />
          <FieldUpload
            label={qrLabel}
            accept="image/*"
            onChange={(e) => handleUpload(e, 'qr_photo_data', setQrLabel)}
            error={errors?.qr_photo_data}
            helperText="Your Telegram QR Code"
          />
        </Box>
        <Box marginY={3} sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
          <Button onClick={handleCancel} type="button" variant="text" sx={{ color: '#000', marginRight: 3 }}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" sx={{ color: 'white' }}>
            Confirm
          </Button>
        </Box>
      </form>
    </CreateLayout>
  );
};

export default CreateCvStep9;