import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableRow, Checkbox, TableContainer, Alert, TableHead } from '@mui/material';
import { getUsersToAddGroup } from '../../../slices/backOffice/userSlice';
import { addMemberToGroup, createCollection, getGroupById } from '../../../slices/backOffice/interviewGpSlice';
import { useParams } from 'react-router-dom';
import ErrorDialog from '../../utils/ErrorDialog';

const types = ['tit','ssw','sw']

const AddNewMemberDialog = ({isOpen, setIsOpen})=>{
  const dispatch = useDispatch()
  const { id } = useParams()
  const [ showAlert,setShowAlert ] = React.useState(false)
  const { usersToAddGroup: userData } = useSelector(state => state.UsersList);
  const { group: singleGroup } = useSelector(state=> state.IntGroup)
  const collectedUser = useSelector(state=> state.IntGroup.collection)

  const fetchData = async (status, type) => {
    await dispatch(getUsersToAddGroup({ status: status, type: type }));
  };

  const handleOnChange = (e,id) => {
    if(e.target.checked){
      dispatch(createCollection([...collectedUser, {user_id: id}]))
    }else{
      const filteredUsers = collectedUser.filter(item=> item.user_id !== id)
      dispatch(createCollection(filteredUsers))
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAdd = async () =>{
    await dispatch(addMemberToGroup({data: collectedUser, gpId: id}));
    await dispatch(getGroupById(id));
    dispatch(createCollection([]))
    handleClose()
  }

  const handleConfirmAlert = ()=>{
    setShowAlert(false)
  }

  React.useEffect(()=>{
    if(singleGroup && singleGroup.data){
      const type = Number(singleGroup.data.type)
      fetchData('free',types[type-1])
    }
  },[])

  return (
    <React.Fragment>
      <ErrorDialog
            type={'danger'}
            confrim={handleConfirmAlert}
            toggle={showAlert}
            setToggle={setShowAlert}
            title={"Sorry!"}
            content={"Different user types are not accepted in this group type."} ></ErrorDialog>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add New Member"}
        </DialogTitle>
        <DialogContent>
            <TableContainer sx={{ maxHeight: 500 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Phone No</TableCell>
                    <TableCell>Email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    userData && userData.data && userData.data.length > 0 ?
                    userData.data.map((user,index)=>
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox checked={collectedUser.some(item=> item.user_id === user.id)} onChange={(e) => handleOnChange(e,user.id)} />
                        </TableCell>
                        <TableCell>{user.name}</TableCell>
                        <TableCell>{user.phone}</TableCell>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ):
                    <TableRow>
                      <TableCell sx={{ py: 1, px: 0 }} colSpan={4}>
                        <Alert severity="warning">There is no users!</Alert>
                      </TableCell>
                    </TableRow>
                  }
                </TableBody>
              </Table>
            </TableContainer>            
        </DialogContent>
        <DialogActions sx={{ mr: 2, mb: 1}}>
          <Button type='button' onClick={handleClose}>Cancel</Button>
          <Button disabled={!(collectedUser.length > 0)} type='submit' onClick={handleAdd} autoFocus>
              Add
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddNewMemberDialog