import React, { Fragment, useEffect, useState } from 'react'
import SmartCardForm from '../common/SmartCardForm'
import SmartCardForm2 from '../common/SmartCardForm2'
import MolCoverletter from './MolCoverletter'
import NDCoverLetter from './NDCoverLetter'
import StAndDiv from './StAndDiv'
import EmployeeInfoTable from './EmployeeInfoTable'
import ListOfWorker from './ListOfWorker'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDataOfDoc } from '../../../../slices/backOffice/pdfDataSlice'
import Loading from '../../../utils/Loading'
import ErrorDialog from '../../../utils/ErrorDialog'
import ListOfWorkerNd from './ListOfWorkerNd'

const SmartCardTitssw = ({gpOrMem,id,toggleDialog}) => {
    const dispatch = useDispatch()
    const gpId = useParams().id
    const { data: singleGroup } = useSelector(state=> state.IntGroup.group)
    const { data: cardData, loading } = useSelector(state=> state.PrintData)
    const [ alertToggle, setAlertToggle ] = useState(false);

    const getData = async()=>{
      const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'smartcard', userId: gpOrMem === 'member' ? id : null}))
      if(dispatchData.error){
        setAlertToggle(true)
      }
    }

    const handleConfirmAlert = async () => {
      setAlertToggle(false);
      toggleDialog(false)
    };

    useEffect(()=>{
      getData()
    },[])

  return (
    <div>
      {
        loading && <Loading></Loading>
      }
        {
          singleGroup && cardData ?
          <>
            <MolCoverletter type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></MolCoverletter>
            <ListOfWorker></ListOfWorker>
            <NDCoverLetter type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></NDCoverLetter>
            <StAndDiv type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></StAndDiv>
            <EmployeeInfoTable type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}></EmployeeInfoTable>
            {
              cardData && cardData.data && cardData.data.OWICards &&
              cardData.data.OWICards.map((pdf,index)=>
                <Fragment key={index}>
                  <SmartCardForm data={pdf}></SmartCardForm>
                  <SmartCardForm2 data={pdf}></SmartCardForm2>
                </Fragment>
              )
            }
            <ListOfWorkerNd type={Number(singleGroup.type) === 1 ? 'tit' : Number(singleGroup.type) === 2 ? 'ssw' : 'sw'}/>
          </>
          :
          <ErrorDialog
            type={'danger'}
            confrim={handleConfirmAlert}
            toggle={alertToggle}
            setToggle={setAlertToggle}
            title={"Oops - something went wrong!"}
            content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
        }
    </div>
  )
}

export default SmartCardTitssw
