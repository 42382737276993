import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
    breakTable: {
        pageBreakBefore: 'always',
    },
});

const Coe11_4 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ datesList1,setDatesList1 ] = useState([])
    // const [ datesList2,setDatesList2 ] = useState([])
 
    const renderTime = (indexData) => {
        if (indexData === 21 || indexData === 23) {
            return "1 :00 ～ 3:30";
        }else if (indexData > 19) {
            return "9 :00 ～ 11:30";
        } else {
            return "8 :00 ～ 12:00";
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (printData && printData.data && printData.data.members && printData.data.trainingDates) {
                const trainingDates = await printData.data.trainingDates.filter(el => el.classStatus === true);
                const slicedDates1 = trainingDates.slice(20, 43);
                const duplicatedDates = [].concat(slicedDates1[20], slicedDates1[21]);
                const modifiedDates = [].concat(slicedDates1.slice(0, 21), duplicatedDates, slicedDates1.slice(21));
                setDatesList1(modifiedDates);
            }
        };
        fetchData();
    }, [printData]);


    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                <tr>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>日付</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '17%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>時間</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>科目（内容）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>講師</Typography>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>（役職・氏名）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>実施場所</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>備考</Typography>
                    </td>
                </tr>
                {
                    datesList1.map((each, index) => (
                        <tr key={index}>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>{
                                    dayjs(each.date).format('YYYY/MM/DD')
                                }</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>
                                    { renderTime(index) }
                                </Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>
                                    {index <= 19 ? "日本語" : index > 19 && index <= 22 ? "日本の歴史・文化" : "生活様式" }
                                </Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>講師・PHOO MYAT CHEL</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>KAKEHASHI Japanese Language Centre</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}></Typography>
                            </td>
                        </tr>
                    ))
                }                 
                </tbody>
            </table>
        </Box>
    )
}

export default Coe11_4