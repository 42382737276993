import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { splitDate } from '../../../utils/splitDate'

const Coe11_11_table = () => {

    const { data: printData } = useSelector(state=> state.PrintData)
    const [ firstStart,setFirstStart ] = useState(null)
    const [ firstEnd,setFirstEnd ] = useState(null)
    const [ secondStart,setSecondStart ] = useState(null)
    const [ secondEnd,setSecondEnd ] = useState(null)
    const [ thirdStart,setThirdStart ] = useState(null)
    const [ thirdEnd,setThirdEnd ] = useState(null)
    const [ orgInfo,setOrgInfo ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members && printData.data.organization){
        const org = printData.data.organization
        setOrgInfo(org)
        const trainingDates = printData.data.trainingDates.filter(el=> el.classStatus === true)
        setFirstStart(trainingDates[0].date)
        setFirstEnd(trainingDates[39].date)
        setSecondStart(trainingDates[40].date)
        setSecondEnd(trainingDates[44].date)
        setThirdStart(trainingDates[45].date)
        setThirdEnd(trainingDates[trainingDates.length -1].date)
      }
    },[printData])

  return (
    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <tbody>
            <tr>
                <td style={{ minWidth: 40 , border: '2px solid #000' }}></td>
                <td style={{ minWidth: 180 , padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    科目（内容）
                </td>
                <td colSpan={2} style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    実施機関の氏名又は名称及び所在地
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    実施場所 <br/>
                    (施設名・所在地等)
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    実施期間
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    実施時間数
                </td>
            </tr>
            <tr>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    1
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', border: '2px solid #000' }}>
                    日本語教育 <br/>
                    - 読み書き <br/>
                    - 会話 <br/>
                    - 文法 <br/>
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', textAlign: 'center', border: '2px solid #000' }}>
                    {orgInfo && orgInfo.nameJp} <br/> 
                    {orgInfo && orgInfo.addressJp} <br/>
                    TEL-{orgInfo && orgInfo.phone} <br/>
                    FAX-{orgInfo && orgInfo.fax}
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    外部委託 <br/> 
                    ■有・☐無
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    KAKEHASHI Japanese <br/>
                    Language Centre <br/> 
                    (NO.(3), 62A  <br/> 
                    Street , Between <br/>
                    30th x 31st  <br/>
                    Streets, 62nd x <br/> 63rd Streets, <br/>
                    Block no. (816), <br/> Pyi Gyi Myat Shin, <br/>
                    Chan Aye Thar San <br/> Township,Mandalay, Myanmar.)<br/>
                    TEL-09-777-510-878
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    {splitDate(firstStart).year}年 {splitDate(firstStart).month}月 {splitDate(firstStart).day}日 <br/> ～ <br/>
                    {splitDate(firstEnd).year}年 {splitDate(firstEnd).month}月 {splitDate(firstEnd).day}日
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'end', border: '2px solid #000' }}>
                    160 <br/> 時間
                </td>
            </tr>
            <tr>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    2
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', border: '2px solid #000' }}>
                    日本での日常<br/>生活に関す <br/>
                    - 知識 <br/>
                    - 日本の史、<br/>
                    文化 <br/>
                    - 生活様式 <br/>
                    - 職場のルール
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', textAlign: 'center', border: '2px solid #000' }}>
                    {orgInfo && orgInfo.nameJp} <br/> 
                    {orgInfo && orgInfo.addressJp} <br/>
                    TEL-{orgInfo && orgInfo.phone} <br/>
                    FAX-{orgInfo && orgInfo.fax}
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    外部委託 <br/> 
                    ■有・☐無
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    KAKEHASHI Japanese <br/>
                    Language Centre <br/> 
                    (NO.(3), 62A  <br/> 
                    Street , Between <br/>
                    30th x 31st  <br/>
                    Streets, 62nd x <br/> 63rd Streets, <br/>
                    Block no. (816), <br/> Pyi Gyi Myat Shin, <br/>
                    Chan Aye Thar San <br/> Township,Mandalay, Myanmar.)<br/>
                    TEL-09-777-510-878
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    {splitDate(secondStart).year}年 {splitDate(secondStart).month}月 {splitDate(secondStart).day}日 <br/> ～ <br/>
                    {splitDate(secondEnd).year}年 {splitDate(secondEnd).month}月 {splitDate(secondEnd).day}日
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'end', border: '2px solid #000' }}>
                    25 <br/> 時間
                </td>
            </tr>
            <tr>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    3
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', border: '2px solid #000' }}>
                    日本での円滑<br/>な技能等の <br/> 修得に資す <br/>
                    - 知識 <br/>
                    - 修得技能の <br/> 目標、内容<br/>
                    - 職場規律、心<br/>構え
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', textAlign: 'center', border: '2px solid #000' }}>
                    {orgInfo && orgInfo.nameJp} <br/> 
                    {orgInfo && orgInfo.addressJp} <br/>
                    TEL-{orgInfo && orgInfo.phone} <br/>
                    FAX-{orgInfo && orgInfo.fax}
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    外部委託 <br/> 
                    ■有・☐無
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    KAKEHASHI Japanese <br/>
                    Language Centre <br/> 
                    (NO.(3), 62A  <br/> 
                    Street , Between <br/>
                    30th x 31st  <br/>
                    Streets, 62nd x <br/> 63rd Streets, <br/>
                    Block no. (816), <br/> Pyi Gyi Myat Shin, <br/>
                    Chan Aye Thar San <br/> Township,Mandalay, Myanmar.)<br/>
                    TEL-09-777-510-878
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                {splitDate(thirdStart).year}年 {splitDate(thirdStart).month}月 {splitDate(thirdStart).day}日 <br/> ～ <br/>
                    {splitDate(thirdEnd).year}年 {splitDate(thirdEnd).month}月 {splitDate(thirdEnd).day}日
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'end', border: '2px solid #000' }}>
                    25 <br/> 時間
                </td>
            </tr>
            <tr>
                <td colSpan={6} style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'center', border: '2px solid #000' }}>
                    合　計　時　間
                </td>
                <td style={{ padding: '5px', fontFamily: 'Mincho', fontSize: '16px', whiteSpace: 'nowrap', textAlign: 'end', border: '2px solid #000' }}>
                    210 時間
                </td>
            </tr>
        </tbody>
    </table>
  )
}

export default Coe11_11_table