import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";
import dayjs from "dayjs";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
    breakTable: {
        pageBreakBefore: 'always',
    },
});

const Coe11_3 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    // const [ firstMember,setFirstMemberData ] = useState(null)
    const [ startDate,setStartDate ] = useState(null)
    const [ endDate,setEndDate ] = useState(null)
    const [ datesList,setDatesList ] = useState([])
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members && printData.data.trainingDates){
        const trainingDates = printData.data.trainingDates.filter(el=> el.classStatus === true)
        // console.log(trainingDates);
        const slicedDates = trainingDates.slice(0,20)
        // const members = printData.data.members
        setDatesList(slicedDates)
        // setFirstMemberData(members[0])
        setStartDate(trainingDates[0].date)
        setEndDate(trainingDates[trainingDates.length - 1].date)
      }
    },[printData])

    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <Box>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>参考様式第４-８号（規則第 54 条第１項第５号関係）</Typography>
                    <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>Ｄ</Typography>
                </Box>
                <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>（日本工業規格Ａ列４）</Typography>
            </Box>
            <Typography sx={{
                fontSize: '22px',
                textAlign: 'center',
                pt: 5,
                letterSpacing: '30px'
            }}>入国前講習実施記録</Typography>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: 5
            }}>
                <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px' }}>（実施期間</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).year} 年</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).month} 月</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(startDate).day} 日 から</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).year} 年</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).month} 月</Typography>
                <Typography sx={{ pl: 3, fontFamily: 'Mincho', fontSize: '20px' }}>{splitDate(endDate).day} 日まで）</Typography>
            </Box>
            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', mb: 1 }}>（対象：別紙「技能実習生一覧表」のとおり）</Typography>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                <tr>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>日付</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '17%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>時間</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>科目（内容）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>講師</Typography>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>（役職・氏名）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>実施場所</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>備考</Typography>
                    </td>
                </tr>
                {
                    datesList.map((each, index) => (
                        <tr key={index}>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>
                                    {dayjs(each.date).format('YYYY/MM/DD')}
                                </Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>8 :00 ～ 12:00</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>日本語</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>講師・PHOO MYAT CHEL</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>KAKEHASHI Japanese Language Centre</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}></Typography>
                            </td>
                        </tr>
                    ))
                }
                </tbody>
            </table>
        </Box>
    )
}

export default Coe11_3