import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
  tableCellStyle: {
    textAlign: "center",
    padding: "5px",
    border: '2px solid #000',
  },
  screen: {
    pageBreakAfter: 'always',
  },
  jpContent: {
    fontFamily: 'Mincho',
    fontSize: '20px',
    lineHeight: '30px'
  }
});

const Coe7_2 = ({index}) => {
  const classes = useStyles();
  const { data: printData } = useSelector(state=> state.PrintData)
  const [ memberData,setMemberData ] = useState([])
  
  useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members[index]
        setMemberData(data)
      }
  },[printData, index])

  const renderTableRow = (number, institution, title, date, amount) => (
    <tr key={number}>
      <td className={classes.tableCellStyle} style={{ width: '35px', border: '2px solid #000' }}>
        <Typography className={classes.jpContent} sx={{ textAlign: 'center' }}>{number}</Typography>
      </td>
      <td className={classes.tableCellStyle} style={{ textAlign: "end", width: "360px" }}>
        <Typography className={classes.jpContent} dangerouslySetInnerHTML={{ __html: institution }} />
      </td>
      <td className={classes.tableCellStyle} style={{ width: '150px' }}>
        <Typography className={classes.jpContent}>{title}</Typography>
      </td>
      <td className={classes.tableCellStyle} style={{ textAlign: "end", width: '150px' }}>
        <Typography className={classes.jpContent}>{date}</Typography>
      </td>
      <td className={classes.tableCellStyle} >
        <Typography className={classes.jpContent}>{amount}</Typography>
      </td>
    </tr>
  );

  return (
    <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
      <table border={1} style={{ borderCollapse: 'collapse', width: '100%' }}>
        <tbody>
          {renderTableRow(3, '(             )', <> その他<br />(           )</>, ' 年 月 日', '（     円 ）')}
          {renderTableRow(4, '(             )', <> その他<br />(           )</>, ' 年 月 日', '（     円 ）')}
          {renderTableRow(5, '(             )', <> その他<br />(           )</>, ' 年 月 日', '（     円 ）')}
          <tr>
            <td colSpan={4} style={{ borderLeft: '1px dotted white', borderBottom: '1px dotted white' }}></td>
            <td align="right" style={{ textAlign: 'center', padding: '5px', border: '2px solid #000' }}>
              <Typography className={classes.jpContent}>計&nbsp; （     円 ）</Typography>
            </td>
          </tr>
        </tbody>
      </table>
      <Box height='10px' />
      <Typography className={classes.jpContent}>（注意）</Typography>
      <Typography className={classes.jpContent}>１　外国の準備機関には、技能実習生の本国での勤務先、入国前講習を実施する機関など技能実習の準備に関与する一切の機関が含まれる。</Typography>
      <Typography className={classes.jpContent}>２　徴収した機関については、名称のほか、括弧書きで技能実習生の送出において果たした役割を記載すること。</Typography>
      <Typography className={classes.jpContent}>３　「その他」の徴収費用については、括弧書きで名目を記載すること。</Typography>
      <Typography className={classes.jpContent}>４　額については、現地通貨又は米ドルで記載し、括弧書きで日本円に換算した金額を記載すること。</Typography>
      <Typography className={classes.jpContent} sx={{ textIndent: '20px', py: '30px', letterSpacing: 3 }}>
        技能実習生から２に記載の金額の費用を徴収し、その内訳について技能実習生に十分に理解させるとともに、送出に関与した他の機関が技能実習生から３に記載の金額の費用を徴収したことを把握しました。また、２及び３に記載の費用以外の費用については、技能実習生が徴収されていないことを確認しました。
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end', py: '10px' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).year} 年</Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).month} 月</Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).day} 日</Typography>
          <Typography className={classes.jpContent}>作成</Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <table border='1' style={{ borderCollapse: 'collapse', width: '80%' }}>
          <tbody>
          <tr>
            <td colSpan={6} style={{ padding: '10px' }}>
              <Typography className={classes.jpContent}>取次送出機関の氏名又は名称</Typography>
            </td>
            <td colSpan={9} style={{ padding: '10px' }}>
              <Typography className={classes.jpContent}>CLOVER MANDALAY CO.,LTD</Typography>
            </td>
          </tr>
          <tr>
            <td style={{ padding: '10px' }}>
              <Typography className={classes.jpContent}>送出機関番号</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>M</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>M</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>R</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>0</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>0</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>0</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>2</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center', borderRight: '1px dotted #808080' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>3</Typography>
            </td>
            <td width='25px' style={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>0</Typography>
            </td>
            <td style={{ padding: '10px' }}>
                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>整理番号</Typography>
            </td>
            <td style={{ textAlign: 'center', width: '30px' , borderRight: '1px dotted #808080' }}></td>
            <td style={{ textAlign: 'center', width: '30px' , borderRight: '1px dotted #808080' }}></td>
            <td style={{ textAlign: 'center', width: '30px' , borderRight: '1px dotted #808080' }}></td>
            <td style={{ textAlign: 'center', width: '30px' }}></td>
            </tr>
            <tr>
                <td colSpan={6} style={{ padding: '20px 10px' }}>
                    <Typography className={classes.jpContent}>作成責任者　役職・氏名</Typography>
                </td>
                <td colSpan={9} style={{ padding: '20px 10px' }}>
                    <Typography className={classes.jpContent}>代表者　CHIT SU WAI</Typography>
                </td>
          </tr>
          </tbody>
        </table>
      </Box>
      <Typography className={classes.jpContent} sx={{ textIndent: '20px', py: '30px' }}>
        取次送出機関及び送出に関与した他の機関に２及び３に記載の金額を支払い、その内訳について理解しました。また、２及び３に記載の費用以外の費用については、徴収されていません。
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', py: '10px' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).year} 年</Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).month} 月</Typography>
          <Typography className={classes.jpContent} sx={{ mr: 3 }}>{splitDate(memberData.coeDcDate).day} 日</Typography>
          <Typography className={classes.jpContent}>作成</Typography>
        </Box>
        <Box sx={{ display: 'flex', pt: '50px' }}>
          <Typography className={classes.jpContent}>技能実習生の署名</Typography>
          <Box sx={{ borderBottom: '1px solid black', height: '20px', width: '200px', ml: '30px' }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Coe7_2;
