import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_3 = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၅။　လက်ခံမည့်လုပ်ငန်းနှင့်စာချုပ်ချုပ်ဆိုပြီးနောက် လိုအပ်သောဂျပန်ဘာသာစကားသင်ကြားခြင်းနှင့် ဂျပန်နိုင်ငံ၏ ဥပဒေ၊ စည်းကမ်း၊ နေထိုင်မှုပုံစံ၊ ယဉ်ကျေးမှု စသည်တို့ကို နားလည်သဘောပေါက်စေရန် အနည်းဆုံး ၁လ နှင့် အထက် စာတွေ့လက်တွေ့သင်ကြားစေပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                5.　受け入れ機関と契約締結後必要な日本語課程と日本の法律、規則、生活、文化を理解させる為最低１ヶ月以上教室内で、又は実習させて教えます。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၆။ ကျွန်ုပ်သည် ဂျပန်နိုင်ငံသို့နေထိုင်ခွင့်လျှောက်ထားရန် လိုအပ်သောစာရွက်စာတမ်းများကို သင့်ထံမှ စစ်ဆေးရယူ၍ COE လျှောက်ထားရန် စာရွက်စာတမ်းပြင်ဆင်ခြင်းများကို ပူးပေါင်းဆောင်ရွက်ပေးမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                6.　私は日本国への在留資格認定書申請に必要な資料をあなたに請求し検討して提出する資料準備に協力します。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၇။ ကျွန်ုပ်သည် သင်၏ ဂျပန်နိုင်ငံသို့မထွက်ခွာမှီ ပြင်ဆင်ရန်ရှိသော အလုပ်သမားဝန်ကြီးဌာနမှ သတ်မှတ်ထားသော Pre-Deparature သင်တန်းသို့ တက်ရောက်ခြင်း၊ ပြည်ပအလုပ်အကိုင်အလုပ်သမား သက်သေခံကတ်ပြားထုတ်ယူခြင်း၊ နိုင်ငံကူးလက်မှတ်ပြုလုပ်ခြင်း၊ ကျန်းမာရေးစစ်ဆေးခြင်းစသည့်တို့ အဆင်ပြေစွာဆောင်ရွက်နိုင်ရန် ပူးပေါင်းဆောင် ရွက်ပေးမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                7.　私はあなたの日本入国前に本国における労働省が定めた出国前準備課程への参加、海外雇用スマートカード交付、パスポート取得、健康診断などを円滑に行えるため協力する。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၈။ ကျွန်ုပ်သည် သင့်အား အေဂျင်စီ၊ ကြီးကြပ်အဖွဲ့အစည်း၊ လက်ခံမည့်လုပ်ငန်း၊ ဂျပန်နိုင်ငံရှိ မြန်မာအလုပ်သမား သံအရာရှိ သို့မဟုတ် မြန်မာသံရုံး၊ မြန်မာနိုင်ငံရှိ ရွှေ့ပြောင်းအလုပ်သမားဌာနခွဲနှင့် Complaints Mechanism Centre တို့၏ဆက်သွယ်ရမည့်လိပ်စာ၊ ဖုန်းနံပါတ်တို့အား ဆက်သွယ်ရရှိနိုင်ရန် ရှင်းလင်းပြောပြပေးမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                8.　私は該当エージェンシー、受入監理団体、受け入れ機関、在日ミャンマー労働管理官、本国労働省の移住労働者担当課、苦情処理センターの連絡先、電話番号などをあなたに伝えます。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၉။ ကျွန်ုပ်သည် သင့်ထံမှ မြန်မာနိုင်ငံ၏ အလုပ်သမားဝန်ကြီးဌာနမှ သတ်မှတ်ထားသော ဝန်ဆောင်ခထက် ကျော်လွန်၍ ကောက်ခံခြင်းမပြုပါ။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                9.　私は本国労働省より規定された手数料以上の金額をあなたから徴収しません。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၁၀။ ကျွန်ုပ်သည် သင်၏ ဂျပန်နိုင်ငံသို့ထွက်ခွာချိန်မှ အမိနိုင်ငံသို့ပြန်လာသည်အထိ တစ်လလျှင် တစ်ကြိမ် အနည်းဆုံး ဆက်သွယ်မည်ဖြစ်ပြီး လိုအပ်လျှင် ဂျပန်နိုင်ငံသို့ လာရောက်၍ တွေ့ဆုံကာ ကိစ္စအဝဝကို အတူတကွ ဖြေရှင်းဆောင်ရွက် သွားမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                10.　あなたが日本へ出国した日から帰国するときまでの間私はあなたに最低一ヶ月に一回連絡します。必要に応じて私は日本に入国してあなたと合って問題解決を一緒に務めます。
            </Typography>
            <Box height='10px' />
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၁၁။ မြန်မာနိုင်ငံနှင့် ဂျပန်နိုင်ငံ၏ နိုင်ငံရေးမူဝါဒပြောင်းလဲခြင်းစသည့် အမျိုးမျိုးသောကိစ္စများကြောင့် သင့်အား စေလွှတ်ရန် လျှောက်ထားခြင်းမဖြစ်နိုင်တော့သည့်အခါ သင်အမှန်တကယ်ကုန်ကျခဲ့သော လက်တွေ့ကုန်ကျစရိတ်များ ကို ပြန်လည်ပေးလျော်မည်မဟုတ်ပါ။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px' , mb: 3 }}>
                11.　ミャンマー国と日本国の政策変更など様々な事情によってあなたを送り出すことが不可能になった場合あなたが実際に負担して経費を私は弁償しません。
            </Typography>
        </Box>
    )
}

export default Coe9_3