import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe11_10 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ memberData,setMemberData ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members){
        const data = printData.data.members
        setMemberData(data)
      }
    },[printData])
    return (
        <Box className={classes.screen} sx={{ p: 15, pt: 25 }}>
            <Typography sx={{
                textAlign: 'end',
                fontSize: '20px',
                fontFamily: 'Mincho'
            }}>別紙 2</Typography>
            <Typography sx={{
                textAlign: 'center',
                padding: '10px 20px',
                fontSize: '22px',
                fontFamily: 'Mincho',
                fontWeight: 'bold',
                py: 5
            }}>個人習得成果評価表</Typography>
            <Typography sx={{
                fontSize: '20px',
                fontFamily: 'Mincho',
                textIndent: '20px',
                py: 5
            }}>
                たいへんよい◎ よい○ あまりよくない△ わるい×
            </Typography>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                            <Typography sx={{ 
                                fontSize: '20px',
                                fontFamily: 'Mincho' }}>受講者氏名</Typography>
                            </td>
                        <td style={{ textAlign: 'center', padding: '10px 20px', fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap'}}>
                             日本語 <br/>
                            （読み書き）
                        </td>
                        <td style={{  textAlign: 'center', padding: '10px 20px', fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>
                            日本語 <br/>
                            （会話）
                        </td>
                        <td style={{  textAlign: 'center', padding: '10px 20px', fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>
                            日本語 <br/>
                            （文法）
                        </td>
                        <td style={{  textAlign: 'center', padding: '10px 20px', fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>
                            <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>日本での生活一般<br/>に関する知識</Typography>
                        </td>
                        <td style={{  textAlign: 'center', padding: '10px 20px', fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>
                            <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>日本での円滑な技能等の修<br/>得に資する知識</Typography>
                        </td>
                    </tr>
                    {
                        memberData &&
                        memberData.map((member,index)=>
                            <tr key={index}>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', whiteSpace: 'nowrap' }}>{member.nameEng}</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '25px' }}>◎</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '25px' }}>○</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '25px' }}>○</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '25px' }}>○</Typography>
                                </td>
                                <td style={{ textAlign: 'center', padding: '10px 20px' }}>
                                    <Typography sx={{ fontFamily: 'Mincho', fontSize: '25px' }}>○</Typography>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </Box>
    )
}

export default Coe11_10