import React, { Fragment, useEffect, useState } from 'react'
import SmartCardForm from '../common/SmartCardForm'
import SmartCardForm2 from '../common/SmartCardForm2'
import NDCoverLetter from './NDCoverLetter'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDataOfDoc } from '../../../../slices/backOffice/pdfDataSlice'
import ErrorDialog from '../../../utils/ErrorDialog'
import Loading from '../../../utils/Loading'

const SmartCardSw = ({gpOrMem,id,toggleDialog}) => {
  const dispatch = useDispatch()
    const gpId = useParams().id
    const { data: cardData, loading } = useSelector(state=> state.PrintData)
    const [ alertToggle, setAlertToggle ] = useState(false);

    const getData = async()=>{
      const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'smartcard', userId: gpOrMem === 'member' ? id : null}))
      if(dispatchData.error){
        setAlertToggle(true)
      }
    }

    const handleConfirmAlert = async () => {
      setAlertToggle(false);
      toggleDialog(false)
    };

    useEffect(()=>{
      getData()
    },[])
    
  return (
    <div>
      {
        loading && <Loading></Loading>
      }
      {
        cardData && cardData.data ?
        cardData.data.map((pdf,index)=>
          <Fragment key={index}>
            <NDCoverLetter ndData={pdf}></NDCoverLetter>
            <SmartCardForm data={pdf} ></SmartCardForm>
            <SmartCardForm2 data={pdf}></SmartCardForm2>
          </Fragment>
        ):
        <ErrorDialog
            type={'danger'}
            confrim={handleConfirmAlert}
            toggle={alertToggle}
            setToggle={setAlertToggle}
            title={"Oops - something went wrong!"}
            content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
      }
    </div>
  )
}

export default SmartCardSw
