import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUserHistory, userHistoryDelete } from '../../../slices/backOffice/userSlice'
import BackButton from '../../../components/utils/BackButton'
import AlertDialog from '../../../components/utils/AlertDialog'
import Loading from '../../../components/utils/Loading'

const UserHistories = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [ his,setHis ] = useState([])
  const [ filteredData,setFilteredData ] = useState([])
  const [ idToDel,setIdToDel ] = useState(false)
  const [ showAlert,setShowAlert ] = useState(false)
  const { loading } = useSelector(state=> state.UsersList)

  const fetchData = async()=>{
    const dispatchData = await dispatch(getUserHistory({userId: id}))
    if(dispatchData.meta.requestStatus === 'fulfilled'){
      setFilteredData(dispatchData.payload.data.job_histories)
      setHis(dispatchData.payload.data.job_histories)
    }
  }

  const handleOnChange = (searchValue)=>{
    const convertedSearchValue = searchValue.toLowerCase()
    const result = his.filter(el=> el.company_name.toLowerCase().includes(convertedSearchValue) || el.organization_name.toLowerCase().includes(convertedSearchValue))
    setFilteredData(result)
  }

  const handleDelete = async (hisId)=>{
    setShowAlert(true)
    setIdToDel(hisId)
  }

  const handleCancelAlert = () => {
    setShowAlert(false);
  };

  const handleConfirmAlert = async () => {
      setShowAlert(false);
      await dispatch(userHistoryDelete({hisId: idToDel}))
      fetchData()
  };

  useEffect(()=>{
    fetchData()
  },[])

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', p: 2, px: 3, height: '100vh', borderRadius: 0 }}>
      { loading && <Loading></Loading> }
      <AlertDialog
        type={'danger'}
        cancel={handleCancelAlert}
        confrim={handleConfirmAlert}
        toggle={showAlert}
        setToggle={setShowAlert}
        title={"Are you sure!"}
        content={"You want to delete this history."}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <BackButton></BackButton>
        <TextField onChange={(e)=>handleOnChange(e.target.value)} size='small' placeholder='Filter by organization or company' sx={{ width: 300 }}></TextField>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>No</TableCell>
              <TableCell align='left'>Date</TableCell>
              <TableCell>Company Name</TableCell>
              <TableCell>Organization Name</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              filteredData.length > 0 ?
              filteredData.map((rec,index)=>
              <TableRow key={index}>
                <TableCell align='center'>{index + 1}</TableCell>
                <TableCell align='left'>{rec.interview_date}</TableCell>
                <TableCell>{rec.company_name}</TableCell>
                <TableCell>{rec.organization_name}</TableCell>
                <TableCell align='center'>
                  <Button color='danger' onClick={()=> handleDelete(rec.id)}>Delete</Button>
                </TableCell>
              </TableRow>
              ):
              <TableRow>
                <TableCell sx={{ py: 1, px: 0 }} colSpan={5}>
                  <Alert severity="warning">There is no histories!</Alert>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default UserHistories