import React, { Fragment, useEffect, useState } from 'react';
import PredepCvForm from '../common/PredepCvForm';
import MoeafCoverLetter from './MoeafCoverLetter';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDataOfDoc } from '../../../../slices/backOffice/pdfDataSlice';
import Loading from '../../../utils/Loading';
import ErrorDialog from '../../../utils/ErrorDialog';

const PredepSw = ({ gpOrMem, id, toggleDialog }) => {
  const dispatch = useDispatch();
  const gpId = useParams().id;
  const { data: pdfData, loading } = useSelector(state => state.PrintData);
  const [ alertToggle, setAlertToggle ] = useState(false);

  const getData = async () => {
    const dispatchData = await dispatch(getDataOfDoc({ gpId: gpId, docType: 'predeparture', userId: gpOrMem === 'member' ? id : null }));
    if(dispatchData.error){
      setAlertToggle(true)
    }
  };
  
  const handleConfirmAlert = async () => {
    setAlertToggle(false);
    toggleDialog(false)
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {
        loading && <Loading></Loading>
      }
      {gpOrMem === 'member' && (pdfData && pdfData.data) ?
        <Fragment>
          <PredepCvForm data={pdfData.data}></PredepCvForm>
          <MoeafCoverLetter data={pdfData.data} />
        </Fragment>
        :
        <ErrorDialog
          type={'danger'}
          confrim={handleConfirmAlert}
          toggle={alertToggle}
          setToggle={setAlertToggle}
          title={"Oops - something went wrong!"}
          content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
      }
      { pdfData && pdfData.data && pdfData.data.predepartures ? (
        pdfData.data.predepartures.map((formData, index) => (
          <Fragment key={index}>
            <PredepCvForm data={formData}></PredepCvForm>
            <MoeafCoverLetter data={formData} />
          </Fragment>
        ))
      ) :
        <ErrorDialog
          type={'danger'}
          confrim={handleConfirmAlert}
          toggle={alertToggle}
          setToggle={setAlertToggle}
          title={"Oops - something went wrong!"}
          content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
      }
    </div>
  );
};

export default PredepSw;
