import React, { Fragment, useEffect, useState } from 'react'
import Coe2 from './Coe2'
import Coe3_1 from './Coe3_1'
import Coe3_4 from './Coe3_4'
import Coe4 from './Coe4'
import Coe6_1 from './Coe6_1'
import Coe6_2 from './Coe6_2'
import Coe7_1 from './Coe7_1'
import Coe7_2 from './Coe7_2'
import Coe8_1 from './Coe8_1'
import Coe8_2 from './Coe8_2'
import Coe9_1 from './Coe9_1'
import Coe9_2 from './Coe9_2'
import Coe9_3 from './Coe9_3'
import Coe9_4 from './Coe9_4'
import Coe9_5 from './Coe9_5'
import Coe9_6 from './Coe9_6'
import Coe9_7 from './Coe9_7'
import Coe9_8 from './Coe9_8'
import Coe10 from './Coe10'
import Coe11_1 from './Coe11_1'
import Coe11_2 from './Coe11_2'
import Coe11_3 from './Coe11_3'
import Coe11_4 from './Coe11_4'
import Coe11_5 from './Coe11_5'
import Coe11_6 from './Coe11_6'
import Coe11_7 from './Coe11_7'
import Coe11_10 from './Coe11_10'
import Coe11_11 from './Coe11_11'
import Coe13 from './Coe13'
import Coe11_12 from './Coe11_12'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDataOfDoc } from '../../../slices/backOffice/pdfDataSlice'
import Coe11_8 from './Coe11_8'
import Coe11_9 from './Coe11_9'
import Loading from '../../utils/Loading'
import ErrorDialog from '../../utils/ErrorDialog'

const Coe = ({gpOrMem,id,toggleDialog}) => {
  const dispatch = useDispatch()
  const { data: printData, loading } = useSelector(state=> state.PrintData)
  const [alertToggle, setAlertToggle] = useState(false);
  const gpId = useParams().id

  const getData = async()=>{
    const dispatchData = await dispatch(getDataOfDoc({gpId: gpId, docType: 'coe-data', userId: gpOrMem === 'member' ? id : null}))
    if(dispatchData.error){
      setAlertToggle(true)
    }
  } 

  const handleConfirmAlert = async () => {
    setAlertToggle(false);
    toggleDialog(false)
  };

  useEffect(()=>{
    getData()
  },[])

  // useEffect(()=>{
  //   if(printData) console.log(printData);
  // },[printData])

  return (
    <>
      {
        loading ?
        <Loading></Loading>
        :
        (printData && printData.data && printData.data.members) ?
        <>
          {
              printData.data.members.map((_, index)=> 
              <Fragment key={index}>
                <Coe2 index={index}></Coe2>
                <Coe3_1 index={index}></Coe3_1>
                <Coe3_4></Coe3_4>
                <Coe6_1 index={index}></Coe6_1>
                <Coe6_2 index={index}></Coe6_2>
                <Coe7_1 index={index}></Coe7_1>
                <Coe7_2 index={index}></Coe7_2>
                {/* each */}
                <Coe9_1 index={index}></Coe9_1>
                <Coe9_2 index={index}></Coe9_2>
                <Coe9_3></Coe9_3>
                <Coe9_4></Coe9_4>
                <Coe9_5></Coe9_5>
                <Coe9_6></Coe9_6>
                <Coe9_7></Coe9_7>
                <Coe9_8 index={index}></Coe9_8>
              </Fragment> )
          }
          {/* group */}
          <Coe4></Coe4>
          {/* group */}
          <Coe8_1></Coe8_1>
          <Coe8_2></Coe8_2>
          {/* group */}
          <Coe10></Coe10>
          {/* group */}
          <Coe11_1></Coe11_1>
          <Coe11_2></Coe11_2>
          <Coe11_3></Coe11_3>
          <Coe11_4></Coe11_4>
          <Coe11_5></Coe11_5>
          <Coe11_6></Coe11_6>
          <Coe11_7></Coe11_7>
          <Coe11_8></Coe11_8>
          <Coe11_9></Coe11_9>
          <Coe11_10></Coe11_10>
          <Coe11_11></Coe11_11>
          <Coe11_12></Coe11_12>
          {/* group */}
          <Coe13></Coe13>
        </>:
        <ErrorDialog
          type={'danger'}
          confrim={handleConfirmAlert}
          toggle={alertToggle}
          setToggle={setAlertToggle}
          title={"Oops - something went wrong!"}
          content={"This page isn't available at that moment, please try again later."} ></ErrorDialog>
      }
    </>
  )
}

export default Coe
