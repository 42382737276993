import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { convertToMyanmarNumbers } from "../../../../utils/enTomm";
import { useSelector } from "react-redux";
import { splitDate } from "../../../../utils/splitDate";
import { getBurmeseMonthName } from "../../../../utils/getBurmeseMonthName";
import { getFullMonthName } from "../../../../utils/getFullMonthName";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    // transform: `rotate(-90deg) translate(0px, 0px)`,
    transform: `rotate(-90deg) translate(0px, -300px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "20px",
    padding: "5px",
    border: "2px solid #000",
  },
});

const EmployeeInfoTable = () => {
    const [ cardData,setCardData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const classes = useStyles();
    
    useEffect(()=>{
        if(pdfData){
            const { data } = pdfData
            setCardData(data)
        }
    },[pdfData])
  return (
    <div className={classes.screen}>
      {
        cardData &&
        <>
          <div style={{ height: "400px", width: "100%" }}></div>
          <Box className={classes.rotatedBox}>
            <Typography
              sx={{
                textAlign: "end",
                fontFamily: "PdsFont",
                fontSize: "20px",
              }}
            >
              ပူးတွဲ - ၂
            </Typography>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                  lineHeight: "40px",
                  fontWeight: "bold",
                }}
              >
                CLOVER MANDALAY CO.,LTD
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
              >
                {splitDate(cardData.smartCardDate).year}ခုနှစ်၊
                &nbsp;{getBurmeseMonthName(getFullMonthName(splitDate(cardData.smartCardDate).month))}လ အတွင်း
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
              >
                တိုင်းဒေသကြီးနှင့် ပြည်နယ်အလိုက် ပြည်ပအလုပ်အကိုင်သမားသက်သေခံကတ်ပြား
                ထုတ်ယူသည့် <br></br> အလုပ်သမားများ၏
              </Typography>
              <Typography
                sx={{ fontFamily: "PdsFont", fontSize: "20px", lineHeight: "40px" }}
              >
                ကိုယ်ရေးအချက်အလက် အခြေပြဇယား
              </Typography>
            </Box>
            <table className={classes.table}>
              <tbody>
                <tr>
                  <td className={classes.tableHeaderCell}>စဉ်</td>
                  <td className={classes.tableHeaderCell}>အလုပ်သမားအမည်</td>
                  <td className={classes.tableHeaderCell}>မွေးသက္ကရာဇ်</td>
                  <td className={classes.tableHeaderCell}>နိုင်ငံကူး လက်မှတ်အမှတ်</td>
                  <td className={classes.tableHeaderCell}>
                    နိုင်ငံသားစီစစ်ရေး ကတ်ပြားအမှတ်
                  </td>
                  <td className={classes.tableHeaderCell}>အဘအမည်</td>
                  <td className={classes.tableHeaderCell}>လူမျိုး/ ဘာသာ</td>
                  <td className={classes.tableHeaderCell}>
                    အမြဲတမ်းနေရပ်လိပ်စာ အပြည့်အစုံ
                  </td>
                </tr>
                {cardData.listOfWorkers.map((person, index) => (
                  <tr key={index}>
                    <td className={classes.tableCell}>
                      {convertToMyanmarNumbers(String(index + 1))}
                    </td>
                    <td className={classes.tableCell}>{person.nameMm}</td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                        {convertToMyanmarNumbers(splitDate(person.dateOfBirth).day)}.
                        {convertToMyanmarNumbers(splitDate(person.dateOfBirth).month)}.
                        {convertToMyanmarNumbers(splitDate(person.dateOfBirth).year)}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {person.passport}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {person.nrcMm}
                    </td>
                    <td
                      className={classes.tableCell}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {person.fatherNameMm}
                    </td>
                    <td className={classes.tableCell}>{person.ethnicityAndReligion}</td>
                    <td className={classes.tableCell}>
                      {person.wardOrVillageMm ? `${person.wardOrVillageMm} ၊ `: ""}
                      {person.townshipMm ? `${person.townshipMm} ၊`: ""}  
                      {person.stateOrDivisionMm ? `${person.stateOrDivisionMm}။`: ""}  
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>
          <div style={{ height: "400px", width: "100%" }}></div>
        </>
      }
    </div>
  );
};

export default EmployeeInfoTable;