import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";

const useStyles = makeStyles({
  screen: {
    pageBreakAfter: "always",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  rotatedBox: {
    width: "100%",
    transform: `rotate(-90deg) translate(0px, -300px)`,
    // overflowX: 'auto',
  },
  table: {
    borderCollapse: "collapse",
    width: "100%",
    lineHeight: "40px",
    marginTop: "15px",
  },
  tableHeaderCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
  },
  tableCell: {
    textAlign: "center",
    verticalAlign: "middle",
    fontFamily: "PdsFont",
    fontSize: "22px",
    padding: "5px",
    border: "2px solid #000",
    minWidth: '50px'
  },
});

const ListOfWorker = () => {
  const [ cardData,setCardData ] = useState(null)
    const pdfData = useSelector(state=> state.PrintData.data)
    const classes = useStyles();
    
    useEffect(()=>{
        if(pdfData){
            const { data } = pdfData
            setCardData(data)
        }
    },[pdfData])
  return (
    <div className={classes.screen}>
      {
        cardData &&
        <>
          <div style={{ height: "500px", width: "100%" }}></div>
            <Box className={classes.rotatedBox}>
              <Typography
                sx={{
                  textAlign: "end",
                  fontFamily: "PdsFont",
                  fontSize: "20px",
                }}
              >
                ပူးတွဲ - ၂
              </Typography>
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography sx={{ fontFamily: "PdsFont", fontSize: '20px', mb: 3, fontSize: '24px' }}>ဂျပန်နိုင်ငံသို့ စေလွှတ်ခွင့်ပြုရန် (OWIC)ကတ် ပြုလုပ်ခွင့် တင်ပြသည့်အလုပ်သမားစာရင်း</Typography>
              </Box>
                <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <tbody>
                    <tr>
                        <td rowSpan={2} className={classes.tableHeaderCell}>စဉ်</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>မြန်မာအေဂျင်စီ အမည်</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>ဂျပန်ကုမ္ပဏီ အမည်</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>အလုပ်အကိုင် အမျိုးအစား</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>စေလွှတ်မည့် အလုပ်သမား အမည်</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>နိုင်ငံကူး လက်မှတ်</td>
                        <td rowSpan={2} className={classes.tableHeaderCell}>သွားရောက်မည့်နိုင်ငံ</td>
                        <td colSpan={3} className={classes.tableHeaderCell}>လုပ်သားဦးရေ</td>
                    </tr>
                    <tr>
                        <td className={classes.tableCell}>ကျား</td>
                        <td style={{ textAlign: 'center',fontFamily: 'PdsFont', fontSize: '20px', verticalAlign: 'middle', padding: '5px', width: '20px' }}>မ</td>
                        <td className={classes.tableCell}>ပေါင်း</td>
                    </tr>
                    {
                        cardData.listOfWorkers.map((person, index) => (
                            <tr key={index}>
                                <td className={classes.tableCell}>{index + 1}</td>
                                <td className={classes.tableCell} style={{ textAlign: 'left' }}>Clover Mandalay Co.,Ltd</td>
                                <td className={classes.tableCell}>{cardData.company.nameEng}</td>
                                <td className={classes.tableCell}>{cardData.jobCategoryEng}</td>
                                <td className={classes.tableCell}>{person.nameEng}</td>
                                <td className={classes.tableCell}>{person.passport}</td>
                                <td className={classes.tableCell}>Japan</td>
                                <td className={classes.tableCell}>{person.gender !== "女" ? "1" : "0"}</td>
                                <td className={classes.tableCell}>{person.gender === "女" ? "1" : "0"}</td>
                                <td className={classes.tableCell}>1</td>
                            </tr>
                        ))
                    }
                    <tr>
                        <td colSpan={7} className={classes.tableCell}>စုစုပေါင်း</td>
                        <td className={classes.tableCell}>{cardData.numberOfWorkersDependingOnGenders.male}</td>
                        <td className={classes.tableCell}>{cardData.numberOfWorkersDependingOnGenders.female}</td>
                        <td className={classes.tableCell}>{Number(cardData.numberOfWorkersDependingOnGenders.male) + Number(cardData.numberOfWorkersDependingOnGenders.female)}</td>
                    </tr>
                  </tbody>
                </table>
            </Box>
          <div style={{ height: "500px", width: "100%" }}></div>
        </>
      }
    </div>
  );
};

export default ListOfWorker;
