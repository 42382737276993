import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { splitDate } from "../../../utils/splitDate";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe8_2 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ tableData,setTableData ] = useState([])
    const [ companyInfo,setCompanyInfo ] = useState(null)
    const [ orgInfo,setOrgInfo ] = useState(null)
    const [ memberCount,setMemberCount ] = useState(0)
    const [ depDate,setDepDate ] = useState(null)
    const [ currentJob,setCurrentJob ] = useState(null)
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.tables){
        const formattedDate = printData.data.initialDepartureDate ? dayjs(printData.data.initialDepartureDate).format('YYYY-MM-DD') : '';
        const members = printData.data.members
        const tables = printData.data.tables
        const firstMem = members[0]
        setTableData(tables)
        setDepDate(formattedDate)
        setCurrentJob(firstMem.experiences[firstMem.experiences.length -1])
        setMemberCount(members.length -1)
        setCompanyInfo(printData.data.company)
        setOrgInfo(printData.data.organization)
      }
    },[printData])

    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ fontSize: '16px', fontFamily: 'Mincho' }}>技能実習生の推薦状-添付名簿</Typography>
            <Typography sx={{ fontSize: '16px', fontFamily: 'PdsFont' }}>နည်းပညာသင်တန်းသားများအတွက် ထောက်ခံစာ အမည်စာရင်း</Typography>
            <Box sx={{
                p: '30px 20px'
            }}>
                <table>
                    <tbody>
                    <tr>
                        <td style={{ width: '250px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>送 出 機 関</Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>:</Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>CLOVER MANDALAY CO., LTD</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingBottom: '20px' }} colSpan={2}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>ပေးပို့သည့်ကုမ္ပဏီ</Typography>
                        </td>
                        <td style={{ paddingBottom: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>CLOVER MANDALAY CO., LTD</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>日本の監理団体</Typography>
                        </td>
                        <td style={{ textAlign: 'center', paddingTop: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>:</Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{orgInfo ? orgInfo.nameJp : ""}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingBottom: '20px' }} colSpan={2}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>ဂျပန်နိုင်ငံရှိ ပူးပေါင်းအဖွဲ့အစည်း</Typography>
                        </td>
                        <td style={{ paddingBottom: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>{orgInfo ? orgInfo.nameEng : ""}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>実 習 実 施 者</Typography>
                        </td>
                        <td style={{ textAlign: 'center', paddingTop: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>:</Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{companyInfo ? companyInfo.nameJp : ""}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingBottom: '20px' }} colSpan={2}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>လက်တွေ့သင်ကြားပေးသူ</Typography>
                        </td>
                        <td style={{ paddingBottom: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>{companyInfo ? companyInfo.nameEng : ""}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>技 能 実 習</Typography>
                        </td>
                        <td style={{ textAlign: 'center', paddingTop: '20px' }}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>:</Typography>
                        </td>
                        <td>
                            <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{splitDate(depDate).year}年{splitDate(depDate).month}月から、{currentJob ? currentJob.jobCategoryJp : ''}</Typography>
                        </td>
                    </tr>
                    <tr>
                        <td style={{ paddingBottom: '20px' }} colSpan={2}>
                            <Typography sx={{ fontSize: '18px', lineHeight: '25px' , fontFamily: 'PdsFont' }}>သင်တန်းကာလ</Typography>
                        </td>
                        <td style={{ paddingBottom: '20px' }}>
                            <Typography sx={{ fontSize: '16px', lineHeight: '25px' , fontFamily: 'PdsFont' }}>{splitDate(depDate).year}ခုနှစ် {splitDate(depDate).month} လ မှ {currentJob ? currentJob.jobCategoryMm : ''}</Typography>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <Box height='20px'/>
                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>名簿</Typography>
                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>အမည်စာရင်း</Typography>
                <Box height='5px'/>
                <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>No.</Typography>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>စဉ်</Typography>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>氏名</Typography>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>အမည်</Typography>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>性別</Typography>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>ကျား/မ</Typography>
                            </td>
                            <td style={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>生年月日</Typography>
                                <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'PdsFont' }}>မွေးသက္ကရာဇ်(နှစ်၊လ၊ရက်)</Typography>
                            </td>
                        </tr>
                        {
                            tableData.map((member, index)=>
                                <tr key={index}>
                                    <td style={{ textAlign: 'center', padding: '5px' }}>
                                        <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{index+1}</Typography>
                                    </td>
                                    <td style={{ textAlign: 'left', paddingLeft: '20px' }}>
                                        <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{member.name}</Typography>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{Number(member.gender) === 1 ? "男" : "女"}</Typography>
                                    </td>
                                    <td style={{ textAlign: 'center' }}>
                                        <Typography sx={{ fontSize: '18px', lineHeight: '30px' , fontFamily: 'Mincho' }}>{splitDate(member.dateOfBirth).year} 年 {splitDate(member.dateOfBirth).month} 月 {splitDate(member.dateOfBirth).day} 日</Typography>
                                    </td>
                                </tr>
                            )
                        }                        
                    </tbody>
                </table>
            </Box>
        </Box>
    )
}

export default Coe8_2