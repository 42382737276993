import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_5 = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၇။ ဂျပန်နိုင်ငံသို့ရောက်ရှိပြီးနောက် ဂျပန်နိုင်ငံ၏ဥပဒေနှင့်စည်းမျဉ်းစည်းကမ်းများကို လေးစားလိုက်နာ၍ မိမိနိုင်ငံနှင့် လူမျိုး၏ဂုဏ်သိက္ခာအတွက် ထိခိုက်နစ်နာမှုမရှိစေရန် ပြုမူနေထိုင်ရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                7.　日本に在留中日本の法律、規則を遵守して母国の名誉を毀損しないいように行動生活する。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၈။ ခေတ္တတိုင်းပြည်ပြန်လိုသည့်အခါ လက်ခံသည့်လုပ်ငန်းနှင့်ကြီးကြပ်သည့်အဖွဲ့အစည်း၏ သဘောတူလက်ခံမှုကို ရယူ၍ သက်ဆိုင်ရာအဖွဲ့အစည်းများသို့ လျှောက်ထားမှုများပြုလုပ်ကာ အသိအမှတ်ပြုမှု ရယူရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                8.　一時帰国したい場合受け入れ機関と監理機関の承諾を得て該当機関に申請して許可をとらなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၉။ ရာဇဝတ်မှုကျူးလွန်ခြင်း၊ အကျင့်စာရိတ္တဖောက်ဖျက်ခြင်း၊ ခိုက်ရန်ဖြစ်ပွားခြင်း၊ မူးရစ်ရမ်းကားခြင်း၊ လုပ်ငန်းခွင် စည်းကမ်းချက်များအား လိုက်နာမှုမရှိခြင်းတို့အတွက် အလုပ်မှ ချက်ချင်း ရပ်စဲခံရမည်ဖြစ်ပြီး ဖြစ်ပေါ်လာသောကိစ္စ အားလုံးအတွက် သင်မှ တာဝန်ယူရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                9.　犯罪、悪性行、喧嘩、酔って喧嘩騒ぎ、就職規則など不服従があった場合直ちに解雇され、発生した問題全てがあなたの責任になります。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၁၀။ သင်သည် သင်တန်းကာလအတွင်း ပြဿနာဖြစ်ပွားသည့်အခါ သပိတ်မှောက်ခြင်း၊ ကန့်ကွက်ဆန္ဒပြခြင်းကဲ့သို့ ဥပဒေနှင့်မညီသည့် လှုပ်ရှားဆောင်ရွက်မှုမျိုး လုပ်ကိုင်ဆောင်ရွက်ခြင်းမပြုလုပ်ဘဲ သက်ဆိုင်ရာအဖွဲ့အစည်းများနှင့် ဆွေးနွေးညှိနှိုင်းတိုင်ပင်ကာ သင့်လျော်သောနည်းလမ်းဖြင့် ငြိမ်းချမ်းစွာ ဖြေရှင်းရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                10.　あなたは技能実習期間中ストライキ	、抗議活動など不法活動に参加しないで問題があった場合関係機関と協議して適切な方法で解決しなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၁၁။ စာချုပ်ပါကာလအတွင်း သင့်ကြောင့် ပြဿနာတစ်စုံတစ်ရာနှင့် ထိခိုက်ပျက်စီးမှု တစ်စုံတရာ ဖြစ်ပွားခဲ့လျှင် သင်မှတာဝန်ယူရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                11.　契約期間中あなたの責に帰すべき事由により問題または損害を与えたらあなたは責任を負わなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၁၂။ သင်သည် ဂျပန်နိုင်ငံတွင် သင်တန်းကာလပြီးဆုံးလျှင် သတ်မှတ်ထားသည့်အတိုင်း တိုင်းပြည်ပြန်ရမည် ဖြစ်ပြီး၊ တရားမဝင်နေထိုင်ခြင်းမပြုရပါ။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                12.　あなたは日本国で技能実習を修了したとき規定より帰国しなければならない。不法滞在はできない。
            </Typography>
            <Typography sx={{ fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold' }}>နေထိုင်ခွင့်ကာလသက်တမ်းတိုးခြင်းနှင့်တိုင်းပြည်ပြန်ရခြင်း</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', fontWeight: 'bold', mb: 3 }}>（在留期間更新と帰国）</Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'PdsFont' }}>
                ၁။ အလုပ်လုပ်ကိုင်စဉ်ကာလအတွင်း နေထိုင်ခွင့်ကာလသက်တမ်းတိုးမြှင့်ခြင်းကို ဂျပန်နိုင်ငံလဝက၏ နေထိုင်ခွင့် ကာလခွင့်ပြုချက်ကိုလိုက်ပြီး လက်ခံမည့်လုပ်ငန်းနှင့်ကြီးကြပ်သည့်အဖွဲ့အစည်း၏ လုပ်ထုံးလုပ်နည်းအတိုင်း ဆောင်ရွက်ရမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontSize: '20px', fontFamily: 'Mincho', mb: 3 }}>
                1.　雇用期間中在留期間更新は日本の出入国在留管理庁の許可に対して受け入れ機関及び監理機関が手続き通り行う。
            </Typography>
        </Box>
    )
}

export default Coe9_5