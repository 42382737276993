import React from "react";
import { Typography } from "@mui/material";

const Coe7_1_3 = ({index}) => {
  const tableCellStyle = {
    textAlign: "center",
    padding: "5px",
    border: '2px solid #000',
  };

  const renderTableRow = (number, institution, title, date, amount) => (
    <tr key={number}>
      <td style={tableCellStyle}>
        <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>{number}</Typography>
      </td>
      <td style={{ ...tableCellStyle, textAlign: "end", width: "360px" }}>
        <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }} dangerouslySetInnerHTML={{ __html: institution }} />
      </td>
      <td style={{ ...tableCellStyle, width: '150px' }}>
        <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>{title}</Typography>
      </td>
      <td style={{ ...tableCellStyle, textAlign: "end" }}>
        <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>{date}</Typography>
      </td>
      <td style={tableCellStyle}>
        <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>{amount}</Typography>
      </td>
    </tr>
  );

  return (
    <>
      <Typography sx={{ fontSize: "22px", fontFamily: 'Mincho', mt: 5, mb: 2 }}>
        ３　外国の準備機関が徴収した費用の名目及び額
      </Typography>

      <table border={1} style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr>
            <td style={{ width: '35px', border: '2px solid #000' }}></td>
            <td style={{ ...tableCellStyle, textAlign: "center", width: "360px" }}>
              <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>
                徴収した機関の名称（送出における役割）
              </Typography>
            </td>
            <td style={tableCellStyle}><Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>名目</Typography></td>
            <td style={{ width: '150px', ...tableCellStyle }}>
              <Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>
                徴収年月日
              </Typography>
            </td>
            <td style={tableCellStyle}><Typography sx={{ fontSize: "20px", lineHeight: '30px', fontFamily: 'Mincho' }}>額</Typography></td>
          </tr>
        </thead>
        <tbody>
          {renderTableRow(1, '(             )', '教育費', ' 年 月 日', '（     円 ）')}
          {renderTableRow(2, '(             )', <> その他<br/>(           )</>, ' 年 月 日', '（     円 ）')}
        </tbody>
      </table>
    </>
  );
};

export default Coe7_1_3;
