import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { splitDate } from "../../../utils/splitDate";
import dayjs from "dayjs";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
    breakTable: {
        pageBreakBefore: 'always',
    },
});

const Coe11_5 = () => {
    const classes = useStyles();
    const { data: printData } = useSelector(state=> state.PrintData)
    const [ firstMember,setFirstMemberData ] = useState(null)
    const [ datesList,setDatesList ] = useState([])

    const renderCourse = (dayIndex)=>{
        if(dayIndex === 0){
            return "生活様式"
        }else if(dayIndex > 0 && dayIndex < 5){
            return "職場のルール"
        }else if(dayIndex > 4 && dayIndex < 10 ){
            return "修得技能の目的・内容"
        }else if(dayIndex > 9){
            return '職場規律・心構え'
        }
    }
    function isEven(number) {
        return number % 2 === 0;
    }
    const renderTime = (indexData)=>{
        if(isEven(indexData)){
            return "1 :00 ～ 3:30"
        }else{
            return "9 :00 ～ 11:30";
        }
    }
  
    useEffect(()=>{
      if(printData && printData.data && printData.data.members && printData.data.trainingDates){
        const trainingDates = printData.data.trainingDates.filter(el=> el.classStatus === true)
        const members = printData.data.members
        const slicedDates = trainingDates.slice(42)
        const result = slicedDates.reduce((acc, item, index) => {
            acc.push(item);
            if (index > 0 ) {
                acc.push({ ...item });
            }
            return acc;
        }, []);
        setDatesList([...result, {},{},{},{},{}])
        setFirstMemberData(members[0])
      }
    },[printData])

    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <table border='1' style={{ borderCollapse: 'collapse', width: '100%' }}>
                <tbody>
                <tr>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>日付</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '17%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>時間</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>科目（内容）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>講師</Typography>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho', whiteSpace: 'nowrap' }}>（役職・氏名）</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>実施場所</Typography>
                    </td>
                    <td style={{ textAlign: 'center', width: '15%' }}>
                        <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>備考</Typography>
                    </td>
                </tr>
                {
                    datesList.map((each, index) => {
                        return index < datesList.length -5 ?
                        <tr key={index}>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>{dayjs(each.date).format('YYYY/MM/DD')}</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>{renderTime(index)}</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>{renderCourse(index)}</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>{"講師・PHOO MYAT CHEL"}</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}>{"KAKEHASHI Japanese Language Centre"}</Typography>
                            </td>
                            <td style={{ textAlign: 'center', padding: '5px' }}>
                                <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}></Typography>
                            </td>
                        </tr>
                        :
                        <tr key={index}>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}> /  / </Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}>{" :  ～  :  "}</Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}></Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}></Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '14px', fontFamily: 'Mincho' }}></Typography>
                        </td>
                        <td style={{ textAlign: 'center', padding: '5px' }}>
                            <Typography sx={{ fontSize: '20px', fontFamily: 'Mincho' }}></Typography>
                        </td>
                    </tr>
                    })
                }
                </tbody>
            </table>
            <Typography sx={{
                fontSize: '18px',
                fontFamily: 'Mincho',
                mt: 5
            }}>（注意）</Typography>
            <Typography sx={{
                fontSize: '18px',
                fontFamily: 'Mincho',
                textIndent: '20px'
            }}>実習監理を行う実習実施者の管理簿及び実習監理に係る技能実習生の管理簿と併せて保存すること。</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'end', mt: 3 }}>{firstMember && splitDate(firstMember.coeDcDate).year} 年&nbsp;&nbsp;&nbsp;&nbsp;{firstMember && splitDate(firstMember.coeDcDate).month} 月&nbsp;&nbsp;&nbsp;&nbsp;{firstMember && splitDate(firstMember.coeDcDate).day} 日</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'end', mt: 5, mr: 48.5 }}>監理団体の名称</Typography>
            <Typography sx={{ fontFamily: 'Mincho', fontSize: '20px', textAlign: 'end', mt: 3 }}>監理責任者の氏名　　　　　　　　　　　　　　　　　<span className="roundedBorder" style={{ fontFamily: 'Mincho', fontSize: '20px' }}>印</span></Typography>
        </Box>
    )
}

export default Coe11_5