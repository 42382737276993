import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BASE_URL, HEADERS } from "../../utils/config";
import { AppSnackbar } from "../../components/utils/AppSnackbar";

const initialState = {
    loading: false,
    data: null,
    error: null
}

export const updateImg = createAsyncThunk(
    'image/updateImg',
    async ({ method,data,type,id }) => {
        try {
            const config = {
                method: method,
                url: `${BASE_URL}/${type}data/image-update/${id}`,
                headers: HEADERS(),
                data: data
            };
            const response = await axios(config);
            AppSnackbar('success', 'Successfully Updated')
            return response.data;
        } catch (error) {
            AppSnackbar('error', 'Failed, something went wrong')
            throw new Error(error);
        }
    }
);

const ImageSlice = createSlice({
    name: "image",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
        .addCase(updateImg.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateImg.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        })
        .addCase(updateImg.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
    }
})

export default ImageSlice.reducer;