import React from "react";
import { makeStyles } from '@mui/styles';
import { Box, Typography } from "@mui/material";

const useStyles = makeStyles({
    screen: {
        pageBreakAfter: 'always',
    },
});

const Coe9_6 = () => {
    const classes = useStyles();
    return (
        <Box className={classes.screen} sx={{ px: 15, pt: 10 }}>
            <Typography sx={{ fontWeight: 'bold', fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold' }}>ကုန်ကျစရိတ်</Typography>
            <Typography sx={{ fontWeight: 'bold', fontFamily: 'Mincho', fontSize: '20px', fontWeight: 'bold', mb: 3 }}>(経費)</Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၁။ လုပ်ငန်းအင်တာဗျူးအောင်မြင်ပြီးနောက် ပြုလုပ်ရမည်ဖြစ်သော ကျန်းမာရေးဆေးစစ်ချက်၊ နိုင်ငံကူးလက်မှတ်၊ ဗီဇာကြေးစသည်တို့အတွက် သင်မှ ကျခံရပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                1.　面接で合格した後健康診断書、パスポート申請、ビザ手数料などの経費はあなたが負担しなければならない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၂။ ဂျပန်နိုင်ငံသို့မထွက်ခွာမှီ ပညာသင်ယူခြင်း၊ နေစားစရိတ်နှင့် သွားလာစရိတ်များကို သင်မှ ကျခံရပါမည်။ သင့်အား လက်ခံမည့်လုပ်ငန်းမှ ထောက်ပံ့မှုများရှိလျှင် ထိုအတွက် ကျခံရန်မလိုအပ်ပါ။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                2.　日本へ入国する前の講座費、生活費、交通費などはあなたが負担しなければならない。ただし、あなたを受け入れる機関がその経費を負担する場合はあなたは負担しない。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၃။ ဂျပန်နိုင်ငံသို့ စေလွှတ်ရန် ဝန်ဆောင်စရိတ်မှာ အလုပ်သမားဝန်ကြီးဌာနမှသတ်မှတ်ထားသော ငွေပမာဏအတွင်း ဖြစ်ပါသည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                3.　日本への送り出し手数料は労働省より規定された金額である。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၄။ ဂျပန်နိုင်ငံသို့အသွားအပြန်ခရီးစရိတ် ၁ ခေါက်စာကို လက်ခံမည့်လုပ်ငန်းမှ ကျခံမည်ဖြစ်သည်။ သင်တန်းကာလ အတွင်း မိမိ၏သဘောဆန္ဒဖြင့် ခေတ္တတိုင်းပြည်ပြန်သည့်အခါ အသွားအပြန်စရိတ်ကို သင်မှ ကျခံရပါမည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                4.　日本への往復航空券は受け入れ機関が負担します。技能実習事業中自らの都合で一時的に帰国する場合、往復航空券はあなたが負担しなければならない。
            </Typography>
            <Typography sx={{ fontWeight: 'bold', fontFamily: 'PdsFontNumber', fontSize: '20px', fontWeight: 'bold' }}>စာချုပ်အကျိုးသက်ရောက်မှုကာလ</Typography>
            <Typography sx={{ fontWeight: 'bold', fontFamily: 'Mincho', fontSize: '20px', fontWeight: 'bold' }}>（有効期間）</Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၁။ အထက်ဖော်ပြပါအချက်အလက်များကို ကိုယ်တိုင်ဖတ်ရှုသိရှိနားလည်သဘောပေါက်ကြပါသဖြင့် မည်သူတစ်ဦး တစ်ယောက်၏ ဖြားယောင်းသွေးဆောင်ခြင်း၊ ခြိမ်းခြောက်ခြင်းမရှိဘဲ မိမိ၏လွတ်လပ်သောသဘောဆန္ဒအရ လက်မှတ် ရေးထိုးပါသည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                1.　当事者は上記の条件をよく読んで理解したうえ他人の誘惑、脅迫の影響なく自らの自由意志で署名します。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၂။ ဤစာချုပ်ကာလသည် သင်နှင့်ကျွန်ုပ်လက်မှတ်စထိုးသည့်ရက်မှစ၍ သင်၏ဂျပန်နိုင်ငံအလုပ်သင်သင်တန်း ပြီးဆုံးသည်အထိ အကျိုးသက်ရောက်မှု ရှိသည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                2.　本契約書の有効期間はあなたと契約した日からあなたが日本で技能実習を修了する日までとする。
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'PdsFont', fontSize: '20px' }}>
                ၃။ သင်တန်းကာလပြီးဆုံးသော်လည်း တိုင်းပြည်မပြန်လာသည့်အခါ ဤစာချုပ်သည် အလိုအလျောက် ပျက်ပျယ်၍ သင်နှင့်ကျွန်ုပ်၏ပတ်သက်မှုမှာပြီးဆုံးမည်ဖြစ်ပြီး ဖြစ်ပေါ်လာသောကိစ္စများကို သင်မှ တာဝန်ယူရမည်ဖြစ်သည်။
            </Typography>
            <Typography sx={{ lineHeight: '35px', fontFamily: 'Mincho', fontSize: '20px', mb: 3 }}>
                3.　技能実習事業が修了しても帰国しない場合本契約書は自動的に無効になってあなたと私の関係は終了してもその後難はあなたの責任になる。
            </Typography>
        </Box>
    )
}

export default Coe9_6